import React from 'react';
import PropTypes from 'prop-types';

// assets
import { General } from 'untitledui-js';

// styles
import * as Styled from 'components/pageJourneyId/_styles/MicrolearningCard.style';

function MicrolearningCardStatus({ wasReviewed }) {
  if (wasReviewed)
    return (
      <Styled.StatusComplete>
        <General.Check />
      </Styled.StatusComplete>
    );
  return (
    <Styled.StatusIncomplete>
      <General.DotsHorizontal />
    </Styled.StatusIncomplete>
  );
}

MicrolearningCardStatus.propTypes = {
  wasReviewed: PropTypes.bool,
};

export default MicrolearningCardStatus;
