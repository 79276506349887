import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import { General } from 'untitledui-js';

// components
import CheckMarkSelection from 'components/buttonFeedback/CheckMarkSelection';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import Navigation from 'components/buttonFeedback/Navigation';
import StarSelection from 'components/buttonFeedback/StarSelection';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/buttonFeedback/_styles/FeedbackCard.style';
import * as Text from 'components/_styles/Text.style';

function FeedbackCard(props) {
  const {
    backClick,
    currentCard,
    handleModalClose,
    nextClick,
    numQuestions,
    question,
    setSubmittedResponses,
    submittedResponses,
  } = props;
  const { t } = useTranslation();
  const lang = useUserLanguage();

  useEffect(() => {
    if (question.label in submittedResponses) return;
    if (question.type === 'str')
      setSubmittedResponses((prev) => ({ ...prev, [question.label]: '' }));
    else
      setSubmittedResponses((prev) => ({
        ...prev,
        [question.label]: undefined,
      }));
  }, [question, setSubmittedResponses, submittedResponses]);

  const handleResponse = (response) =>
    setSubmittedResponses((prev) => ({ ...prev, [question.label]: response }));
  const handleFeedback = (input) => handleResponse(String(input.target.value));

  const getFeedbackInput = () => {
    if (question.type === 'bool')
      return (
        <CheckMarkSelection
          response={submittedResponses[question.label]}
          setResponse={handleResponse}
        />
      );
    if (question.type === 'int')
      return (
        <StarSelection
          response={submittedResponses[question.label]}
          setResponse={handleResponse}
        />
      );
    return (
      <Styled.TextArea
        onChange={(e) => handleFeedback(e)}
        placeholder={t('buttonFeedback:textAreaPlaceholder')}
        rows="5"
        value={submittedResponses[question.label] || ''}
      />
    );
  };

  return (
    <>
      <Styled.CloseSection>
        <Styled.CloseButton onClick={handleModalClose}>
          <General.XClose />
        </Styled.CloseButton>
      </Styled.CloseSection>
      <ErrorBoundary>
        <Styled.FormContent>
          <Styled.FormQuestion>
            <Text.CaptionSemiboldCap>
              {`${t('buttonFeedback:question', {
                current: currentCard + 1,
                total: numQuestions,
              })}`}
            </Text.CaptionSemiboldCap>
            <Text.H4Bold>{question[`description_${lang}`]}</Text.H4Bold>
          </Styled.FormQuestion>
          {getFeedbackInput()}
        </Styled.FormContent>
        <Navigation
          backClick={backClick}
          currentCard={currentCard}
          mandatoryAnswer={question.is_mandatory}
          nextClick={nextClick}
          numQuestions={numQuestions}
          response={submittedResponses[question.label]}
        />
      </ErrorBoundary>
    </>
  );
}

FeedbackCard.propTypes = {
  backClick: PropTypes.func.isRequired,
  currentCard: PropTypes.number.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  nextClick: PropTypes.func.isRequired,
  numQuestions: PropTypes.number.isRequired,
  setSubmittedResponses: PropTypes.func.isRequired,
  submittedResponses: PropTypes.object,
};

export default FeedbackCard;
