import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import i18n from 'i18n';
import PropTypes from 'prop-types';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';
import DemoRegistration from 'components/pageDemoRegistration/_index';
import PageLayout from 'components/_pageLayout/PageLayout';
import SignInPortalPage from 'components/pageSignIn/_index';

// context
import { useUser } from 'contexts/UserContext';
import StyleProvider from 'contexts/ThemeContext';

// hooks & helpers
import routesConfig from 'constants/routesConfig.json';
import useApi from 'hooks/useApi';

// styles
import * as Container from 'components/_pageLayout/_styles/PageLayout.style';

// To identify user in pendo analytics
// https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-Guide-To-Installing-Pendo
const pendoAnalytics = (apiKey, user) => {
  /* eslint-disable */
  (function (p, e, n, d, o) {
    let v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push'](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');

  pendo.initialize({
    visitor: { id: user.email },
    account: { id: user.email },
  });
};

const AppContent = ({ user, loginCode, handleDefaultLanguage }) => {
  const location = useLocation();

  let path = location.pathname;

  if (path.startsWith(routesConfig.DEMO_REGISTRATION) && !user?.id) {
    if (path.endsWith('/')) {
      path = path.substring(0, path.length - 1);
    }
    const lastIndex = path.lastIndexOf('/');
    handleDefaultLanguage(path.substring(lastIndex + 1, path.length));

    return (
      <Container.ScreenContainer id="screen">
        <Container.MediumPageContainer>
          <DemoRegistration />
        </Container.MediumPageContainer>
      </Container.ScreenContainer>
    );
  }

  if (user == null || loginCode) return <ApiLoading />;
  if (user.status === 401) return <SignInPortalPage />;

  return (
    <Suspense fallback="loading">
      <PageLayout />
    </Suspense>
  );
};

AppContent.propTypes = {
  user: PropTypes.object,
  loginCode: PropTypes.string,
};

function App() {
  const { data, callApi } = useApi();
  const { data: user } = useUser();
  const queryParams = new URLSearchParams(window.location.search);
  const loginCode = queryParams.get('login_code');
  queryParams.delete('login_code');

  useEffect(() => {
    if (loginCode) callApi('/login/token/', 'post', { login_code: loginCode });
  }, [callApi, loginCode]);

  if (data.status === 'success') {
    localStorage.setItem(
      'authorization',
      JSON.stringify({ token: data.response.token })
    );
    window.location.replace('/');
  }

  const handleDefaultLanguage = (override = null) => {
    if (override) {
      console.log('override', override);
      i18n.changeLanguage(override);
      return;
    }

    if (user != null && user.status !== 401) i18n.changeLanguage(user.language);
  };

  useEffect(() => {
    handleDefaultLanguage();
  }, [user]);

  user && pendoAnalytics(process.env.REACT_APP_PENDO_API_KEY, user);

  return (
    <StyleProvider>
      <Router>
        <AppContent
          user={user}
          loginCode={loginCode}
          handleDefaultLanguage={handleDefaultLanguage}
        />
      </Router>
    </StyleProvider>
  );
}

export default App;
