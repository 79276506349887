import React from 'react';
import PropTypes from 'prop-types';

import { General } from 'untitledui-js';
import { useTranslation } from 'react-i18next';

import * as Styled from 'components/pageECoach/_styles/Header.style';

export default function HomeButton({ action }) {
  const { t } = useTranslation('pageEcoach');

  return (
    <Styled.HeaderButton onClick={action}>
      <General.Home05 size={'15'} />
      {t('Home')}
    </Styled.HeaderButton>
  );
}

HomeButton.propTypes = {
  action: PropTypes.func,
};
