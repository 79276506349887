import styled from 'styled-components';
import * as Text from 'components/_styles/Text.style';
import colours from 'constants/colours';
import device from 'constants/devices';
import { FormInputSelect } from 'components/buttonBooking/_styles/BookingForm.style';

export const LanguageSection = styled(FormInputSelect)`
  align-items: center;
  cursor: pointer;
  gap: 5px;
  justify-content: space-between;

  @media (max-width: ${device.tablet}) {
    gap: 30px;
  }
`;

export const Language = styled('div')`
  align-items: center;
  display: flex;
  gap: 5px;
  position: relative;

  p {
    color: ${colours.neutralCool[800]};
  }

  svg path {
    stroke: ${colours.neutralCool[900]};
  }
`;

export const LanguageOptions = styled('div')`
  display: flex;
  gap: 30px;
  padding: 3px;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const LanguageOption = styled('div')`
  display: flex;
  justify-content: space-between;

  &:hover p {
    color: ${colours.neutralCool[800]};
  }
`;

export const FormInputText = styled(Text.P2Semibold)`
  color: ${colours.neutralCool[500]};
`;
