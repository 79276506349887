import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import { Link } from '@mui/material';
import TextField from '@mui/material/TextField';
import * as Cards from 'components/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

export const ScreenContainer = styled('div')`
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100vw;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
  }
`;

export const FormSection = styled('div')`
  align-items: center;
  background-color: #f7f7f5;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  justify-content: center;

  @media (max-width: ${device.laptop}) {
    background-color: white;
    flex-direction: column;
  }
`;

export const QuoteColumn = styled('div')`
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 70px;

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`;

export const Card = styled(Cards.BasicCard)`
  background-color: white;
  gap: 10px;
  max-width: 450px;
  padding: 70px;

  @media (max-width: ${device.laptop}) {
    box-shadow: unset;
    padding: 30px;
    text-align: center;
  }
`;

export const Input = styled(TextField)`
  background-color: #f5f5f3;
  border: none;
  border-radius: 50px;
  margin-top: 30px;

  fieldset {
    border: none;
    border-radius: 50px;
  }

  input {
    font-size: 16px;
  }

  label.Mui-focused,
  label.MuiFormLabel-filled {
    top: -10px;
  }
`;

export const LogoText = styled('img')`
  height: 36px;
  left: 15px;
  position: absolute;
  top: 15px;
`;

export const Success = styled('div')`
  align-items: center;
  background-color: #d8ffcc;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  padding: 50px;
`;

export const SuccessMessage = styled(Text.P1)`
  color: #38662b;
  font-weight: 700;
  text-align: center;
`;

export const Resend = styled(Text.P1)`
  color: #38662b;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
`;

export const LanguageToggle = styled('div')`
  background-color: white;
  border-radius: 10px;
  margin-bottom: 30px;
`;

export const Quote = styled(Text.P2Semibold)`
  color: white;
`;

export const HelpLink = styled(Link)`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  text-decoration-color: ${colours.neutralCool[800]};
`;
