const translations = {
  en: {
    emailLabel: 'Email Address',
    instructions1: 'Enter your work email & we will',
    instructions2: 'send you an access link.',
    login: 'Log in',
    quote: `The advice I’d give to somebody that’s silently struggling is, you don’t have to live that way. You don’t have to struggle in silence. You can be un-silent. You can live well with a mental health condition, as long as you open up to somebody about it, because it’s really important you share your experience with people so that you can get the help that you need.`,
    resend: 'Resend',
    submitButton: 'Get Access Link',
    successMessage: 'An access link has been sent to your email',
    help: 'Need help?',
  },
  fr: {
    emailLabel: 'Votre courriel au travail',
    instructions1: 'Entrez votre courriel au travail et nous',
    instructions2: "vous enverrons un lien d'accès.",
    login: 'Se connecter',
    quote:
      "Le conseil que je donnerais à quelqu'un qui lutte en silence, c'est que vous n'avez pas à vivre de cette façon. Vous n'êtes pas obligé de lutter en silence. Vous pouvez ne pas être silencieux. Vous pouvez bien vivre avec une maladie mentale, à condition de vous ouvrir à quelqu'un, parce qu'il est vraiment important de partager votre expérience avec les gens pour que vous puissiez obtenir l'aide dont vous avez besoin.",
    resend: 'Renvoyer',
    submitButton: "Obtenir le lien d'accès",
    successMessage: "Un lien d'accès a été envoyé à votre adresse courriel",
    help: "Besoin d'aide?",
  },
};

export default translations;
