import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// components
import EndCard from 'components/buttonFeedback/EndCard';
import FeedbackCard from 'components/buttonFeedback/FeedbackCard';

// hooks
import useApi from 'hooks/useApi';

function FeedbackForm({ bookingId, handleModalClose, redirect }) {
  const { data: questions, callApi: getQuestions } = useApi();
  const { callApi } = useApi();

  const [currentCard, setCurrentCard] = useState(0);
  const [submittedResponses, setSubmittedResponses] = useState({});

  const nextClick = () => setCurrentCard((prev) => prev + 1);
  const backClick = () => setCurrentCard((prev) => prev - 1);

  useEffect(() => {
    if (questions.response && currentCard === questions.response.length)
      callApi(`/bookings/${bookingId}/surveys/`, 'post', submittedResponses);
  }, [bookingId, callApi, currentCard, questions, submittedResponses]);

  useEffect(() => {
    getQuestions('/surveys/choices/');
  }, [getQuestions]);

  if (questions.status !== 'success' && !questions.response) return null;
  return (
    <>
      {currentCard < questions.response.length ? (
        <FeedbackCard
          backClick={backClick}
          currentCard={currentCard}
          handleModalClose={handleModalClose}
          nextClick={nextClick}
          numQuestions={questions.response.length}
          question={questions.response[currentCard]}
          setSubmittedResponses={setSubmittedResponses}
          submittedResponses={submittedResponses}
        />
      ) : (
        <EndCard redirect={redirect} />
      )}
    </>
  );
}

FeedbackForm.propTypes = {
  bookingId: PropTypes.string,
  handleModalClose: PropTypes.func,
  redirect: PropTypes.func,
};

export default FeedbackForm;
