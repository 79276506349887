import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import IntroCard from 'components/pageECoach/aboutECoach/IntroCard';
import PrivacyCard from 'components/pageECoach/aboutECoach/PrivacyCard';
import ChatBubble from 'components/pageECoach/cardTemplates/ChatBubble';

export default function AboutECoachCard({ showExplainer, toggleExplainer }) {
  const { t } = useTranslation('pageEcoach');
  const [showIntro, setShowIntro] = useState(true);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const onBackClick = () => {
    setShowIntro(true);
    setShowPrivacy(false);
  };

  const onPrivacyClick = () => {
    setShowIntro(false);
    setShowPrivacy(true);
  };

  const navigation = showPrivacy
    ? {
        action: () => onBackClick(),
        text: t('aboutECoach.privacy.back'),
      }
    : null;

  return (
    <>
      {showExplainer && (
        <ChatBubble header={t('aboutECoach.header')} navigation={navigation}>
          {showIntro && (
            <IntroCard
              onPrivacyClick={onPrivacyClick}
              toggleExplainer={toggleExplainer}
            />
          )}
          {showPrivacy && <PrivacyCard toggleExplainer={toggleExplainer} />}
        </ChatBubble>
      )}
    </>
  );
}

AboutECoachCard.propTypes = {
  showExplainer: PropTypes.bool,
  toggleExplainer: PropTypes.func,
};
