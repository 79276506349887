import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Time } from 'untitledui-js';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/buttonBooking/_styles/ScheduleOption.style';
import * as Text from 'components/_styles/Text.style';

function ScheduleOption(props) {
  const { handleTimetableSelect, selected, setRecurrence, timetable } = props;

  const { t } = useTranslation();
  const lang = useUserLanguage();

  const schedulesLength = timetable.schedules.length;
  const firstDate = dayjs(timetable.schedules[0].starts_at);
  const secondDate =
    schedulesLength > 1 ? dayjs(timetable.schedules[1].starts_at) : firstDate;
  const lastDate = dayjs(timetable.schedules[schedulesLength - 1].starts_at);
  const callDuration = 20;
  const startTime = firstDate.format('LT');
  const endTime = lastDate.add(callDuration, 'minute').format('LT');
  const firstDay = firstDate.locale(lang).format('dddd');
  const lastDay = lastDate.locale(lang).format('dddd');

  const recurrence = {
    id: 'onDay',
    firstDate,
    firstDay,
    lastDate,
    lastDay,
    startTime,
    endTime,
  };

  if (secondDate.diff(firstDate, 'day') > 7) recurrence.id = 'everyOther';
  if (firstDay !== lastDay) recurrence.id = 'onDays';

  const handleSelect = () => {
    setRecurrence(recurrence);
    handleTimetableSelect(timetable);
  };

  const getRecurrenceString = () => {
    if (recurrence.id === 'everyOther')
      return t('buttonBooking:everyOther', { day: firstDay });
    if (recurrence.id === 'onDay')
      return t('buttonBooking:onDay', { day: firstDay });
    if (recurrence.id === 'onDays')
      return t('buttonBooking:onDays', { day1: firstDay, day2: lastDay });
    return null;
  };

  return (
    <Styled.ScheduleOption onClick={() => handleSelect()} selected={selected}>
      <Styled.DateSection>
        {!firstDate.isSame(lastDate) && (
          <Styled.DateConnectorIcon>
            <Styled.DateConnectorDot />
            <Styled.DateConnectorLine />
            <Styled.DateConnectorDot />
          </Styled.DateConnectorIcon>
        )}
        {firstDate && lastDate && (
          <Styled.Dates>
            <Text.P3Semibold>
              {t('buttonBooking:starts', {
                start: firstDate.locale(lang).format('LL'),
              })}
            </Text.P3Semibold>
            <Text.P3Semibold>
              {t('buttonBooking:ends', {
                end: lastDate.locale(lang).format('LL'),
              })}
            </Text.P3Semibold>
          </Styled.Dates>
        )}
      </Styled.DateSection>
      {startTime && endTime && (
        <Styled.DateDetailsSection>
          <Time.Clock />
          <Styled.DateDetailsText>{`${startTime} - ${endTime} (${dayjs.tz.guess()})`}</Styled.DateDetailsText>
        </Styled.DateDetailsSection>
      )}
      <Styled.DateDetailsSection>
        <Time.Calender />
        <Styled.DateDetailsText>{getRecurrenceString()}</Styled.DateDetailsText>
      </Styled.DateDetailsSection>
    </Styled.ScheduleOption>
  );
}

ScheduleOption.propTypes = {
  handleTimetableSelect: PropTypes.func,
  selected: PropTypes.bool,
  setRecurrence: PropTypes.func,
  timetable: PropTypes.object,
};

export default ScheduleOption;
