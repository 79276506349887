import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import ThumbsUpImg from 'assets/check-green-23.svg';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/buttonBooking/_styles/EndCard.style';
import * as Text from 'components/_styles/Text.style';

function EndCard(props) {
  const { journeyTitle, recurrence } = props;

  const { t } = useTranslation();
  const lang = useUserLanguage();
  const refreshPage = () => window.location.reload();

  const getRecurrenceString = () => {
    if (recurrence.id === 'everyOther')
      return t('buttonBooking:endCard.everyOther', {
        day: recurrence.firstDay,
        startTime: recurrence.startTime,
        endTime: recurrence.endTime,
      });
    if (recurrence.id === 'onDay')
      return t('buttonBooking:endCard.every', {
        day: recurrence.firstDay,
        startTime: recurrence.startTime,
        endTime: recurrence.endTime,
      });
    if (recurrence.id === 'onDays')
      return t('buttonBooking:endCard.everyMultiple', {
        firstDay: recurrence.firstDay,
        lastDay: recurrence.lastDay,
        startTime: recurrence.startTime,
        endTime: recurrence.endTime,
      });
    return null;
  };

  const getIntervalString = () =>
    t('buttonBooking:endCard.from', {
      startDate: dayjs(recurrence.firstDate).locale(lang).format('LL'),
      endDate: dayjs(recurrence.lastDate).locale(lang).format('LL'),
    });

  return (
    <>
      <Styled.TopSection>
        <Styled.Image src={ThumbsUpImg} />
        <Styled.Details>
          <Text.H1Bold>{t('buttonBooking:reserved')}</Text.H1Bold>
          {journeyTitle && <Text.H4Bold>{journeyTitle}</Text.H4Bold>}
          <Text.P3Semibold>
            {`${getRecurrenceString()} (${dayjs.tz.guess()})`}
            <br />
            {getIntervalString()}
          </Text.P3Semibold>
        </Styled.Details>
      </Styled.TopSection>
      <Styled.BottomSection>
        <Buttons.Primary onClick={refreshPage}>
          {t('buttonBooking:done')}
        </Buttons.Primary>
      </Styled.BottomSection>
    </>
  );
}

EndCard.propTypes = {
  journeyTitle: PropTypes.string,
  recurrence: PropTypes.object,
};

export default EndCard;
