import styled from 'styled-components';
import * as Cards from 'components/_styles/Cards.style';
import device from 'constants/devices';

export const Card = styled(Cards.BasicCard)`
  align-items: center;
  gap: 30px;
  height: 775px;
  padding: 80px;

  @media (max-width: ${device.tablet}) {
    border-radius: 35px;
    height: unset;
    min-height: 550px;
    padding: 30px;
    width: 90%;
  }
`;

export const TopSection = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 420px;
  justify-content: space-around;

  @media (max-width: ${device.tablet}) {
    padding: 0 40px;
  }
`;

export const Image = styled('img')`
  @media (max-width: ${device.tablet}) {
    width: 180px;
  }
`;

export const Details = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
`;

export const BottomSection = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-end;
  width: 100%;
`;
