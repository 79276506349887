import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

export const Section = styled('div')`
  display: flex;
  justify-content: center;
`;

export const Container = styled('div')`
  align-items: center;
  background-color: ${colours.neutralCool[800]};
  border-radius: 50px;
  bottom: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: ${(props) =>
    props.cta === 'true' ? 'space-around' : 'space-between'};
  max-height: 239px;
  max-width: 640px;
  min-height: 239px;
  padding: 30px;
  position: absolute;
  width: 95%;

  @media (max-width: ${device.tablet}) {
    border-radius: 10px;
    padding: 20px;
    gap: 15px;
    height: 100%;
    padding: 20px;
  }
`;

export const Center = styled('div')`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  padding: 30px 0;
  position: relative;

  h5 {
    color: ${colours.shade[0]};
  }
`;

export const Content = styled('div')`
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

export const ContentCentered = styled(Content)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ScrollGradient = styled('div')`
  background-image: linear-gradient(rgba(40, 52, 66, 0), rgba(40, 52, 66, 1));
  bottom: 0;
  height: 30px;
  position: sticky;
  width: 100%;
`;

export const ContentText = styled(Text.P3Semibold)`
  color: ${colours.shade[0]};
`;

export const ContentTextCentered = styled(ContentText)`
  text-align: center;
`;

export const Navigation = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
`;

export const CTA = styled(Navigation)`
  max-width: 300px;
`;

export const GuideProgressContainer = styled('div')`
  background-color: ${colours.neutralCool[900]};
  border-radius: 100px;
  bottom: 25px;
  flex: 1;
  max-height: 9px;
  min-height: 9px;
  min-width: 100px;
`;

export const GuideProgress = styled('div')`
  background-color: ${colours.neutralCool[600]};
  border-radius: 100px;
  display: flex;
  max-height: 9px;
  min-height: 9px;
  width: ${(props) => `${props.progress}%`};
`;

export const SuccessImgContainer = styled('div')`
  max-width: 500px;
  padding: 50px;
`;

export const SuccessImg = styled('img')`
  width: 100%;
`;
