import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// components
import { Files } from 'untitledui-js';

import ApiLoading from 'components/_pageLayout/ApiLoading';
import DownloadButton from 'components/pageECoach/suggestions/DocumentDownloadButton';
import ShareButton from 'components/pageECoach/suggestions/DocumentShareButton';

// hooks
import useApi from 'hooks/useApi';
import useBrowserDetection from 'hooks/useBrowserDetection';

// styles
import * as Styled from 'components/pageECoach/_styles/DocumentRow.style';
import * as Text from 'components/_styles/Text.style';

export default function DocumentRow({
  docParentId,
  docTitle,
  language,
  onPage,
  session,
}) {
  const { data, callApi: getDocument } = useApi();
  const browser = useBrowserDetection();

  const docPageURL = `/documents/${docParentId}/pdf?language=${language}`;
  const docPageURLWithSource = `${docPageURL}&source=${session}`;
  const LinkComponent = ['safari', 'edge', 'ie'].includes(browser)
    ? Styled.AnchorLink
    : Styled.RouterLink;

  useEffect(() => {
    getDocument(docPageURL, 'pdf');
  }, [getDocument, docPageURL]);

  if (data.status === 'loading') return <ApiLoading />;

  if (!['safari', 'edge', 'ie'].includes(browser))
    return (
      <Styled.StyledLink
        as={LinkComponent}
        to={docPageURLWithSource}
        target="_blank"
        rel="noopener noreferrer"
      >
        🔗 {docTitle}
      </Styled.StyledLink>
    );

  if (['safari', 'edge', 'ie'].includes(browser) && onPage)
    return (
      <Styled.StyledCard>
        <div style={{ width: 'fit-content' }}>
          <Files.File05 size={'80'} strokeWidth={'1.25'} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Text.SubheadingSemibold>{docTitle}</Text.SubheadingSemibold>
          <div style={{ display: 'flex', gap: '20px' }}>
            <DownloadButton href={data?.response.pdfUrl} name={docTitle} />
            <ShareButton docURL={docPageURL} />
          </div>
        </div>
      </Styled.StyledCard>
    );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Text.P1Semibold>🔗 {docTitle}</Text.P1Semibold>
      <div style={{ display: 'flex', gap: '20px' }}>
        <DownloadButton href={data?.response.pdfUrl} name={docTitle} />
        <ShareButton docURL={docPageURL} />
      </div>
    </div>
  );
}

DocumentRow.propTypes = {
  docParentId: PropTypes.string,
  docTitle: PropTypes.string,
  language: PropTypes.string,
  onPage: PropTypes.bool,
  session: PropTypes.string,
};
