import styled from 'styled-components';
import Alert from '@mui/material/Alert';
import colours from 'constants/colours';
import device from 'constants/devices';

export const ScreenContainer = styled('div')`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(props) =>
    props.inECoach === 'true' ? 'column' : 'column'};
  height: calc(100 * var(--vh));
  overflow: auto;
  scroll-behavior: smooth;
  width: 100vw;
`;

export const PageContainer = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  max-width: 1200px;
  padding: 30px;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    padding: 10px;
  }
`;

export const MediumPageContainer = styled(PageContainer)`
  max-width: 1200px;
`;

export const Page = styled('div')`
  display: flex;
  flex: 1;
`;

export const DemoMessage = styled(Alert)`
  align-items: center;
  background-color: ${colours.neutralCool[200]};
  border-radius: 0;
  box-sizing: border-box;
  color: ${colours.neutralCool[800]};
  display: flex;
  justify-content: center;
  padding: 0 30px;
  width: 100%;

  svg {
    height: 20px;
    width: 20px;
  }
  svg path {
    fill: ${colours.neutralCool[800]};
  }
`;
