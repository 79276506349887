import styled from 'styled-components';
import colours from 'constants/colours';
import IconButton from '@mui/material/IconButton';

export const Section = styled('div')`
  box-sizing: border-box;
  padding: 0 0 30px 0;
  width: 100%;
`;

export const Button = styled(IconButton)`
  svg path {
    stroke: ${(props) =>
      props.disabled ? colours.neutralCool[400] : colours.primary[500]};
  }
`;
