import styled from 'styled-components';
import colours from 'constants/colours';
import shadows from 'constants/dropShadows';

// Header Section
export const HeaderSection = styled('div')`
  background-color: ${colours.neutralWarm[50]};
  display: flex;
  gap: 10px;
  padding: 5px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 5;
`;

export const HeaderButton = styled('div')`
  align-items: center;
  background-color: ${colours.shade[0]};
  border-radius: 30px;
  box-shadow: ${shadows.XS};
  color: ${colours.neutralCool[500]};
  cursor: pointer;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  gap: 5px;
  line-height: 18px;
  max-height: 44px;
  padding: 10px;
  user-select: none;
  width: fit-content;

  svg {
    height: 15;
    width: 15;
    stroke-width: 2.5;
  }

  svg path {
    stroke: ${colours.neutralCool[500]};
  }

  &:hover {
    background-color: ${colours.neutralCool[100]};
  }

  &:focus {
    background-color: ${colours.neutralCool[100]};
  }

  &:active {
    background-color: ${colours.neutralCool[100]};
  }
`;
