import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

// constants
import routesConfig from 'constants/routesConfig.json';

// components, styles, assets
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import * as History from 'components/pageECoach/_styles/History.style';
import * as Text from 'components/_styles/Text.style';
import { Arrow } from 'untitledui-js';
import { Stack } from '@mui/material';

// hooks
import useApi from 'hooks/useApi';
import useUserLanguage from 'hooks/useUserLanguage';

export default function ECoachSessionList({ navigate }) {
  const { data: sessionList, callApi: getSessionList } = useApi();
  const { t } = useTranslation('pageEcoach');
  const lang = useUserLanguage();

  useEffect(() => {
    getSessionList(routesConfig.ECOACH.SESSIONS);
  }, [getSessionList]);

  const sessions = sessionList.status === 'success' ? sessionList.response : [];

  if (sessions?.length === 0) return <></>;
  return (
    <Stack spacing={4}>
      <Text.SubheadingSemibold>
        {t('Previous sessions')}
      </Text.SubheadingSemibold>
      <Cards.MultiCardSection>
        {sessions.map((session) => (
          <History.Card key={session.id} onClick={() => navigate(session.id)}>
            <Stack spacing={1}>
              <Stack justifyContent={'space-between'} direction={'row'}>
                <History.Date>
                  {dayjs().locale(lang).to(dayjs(session.created_at))}
                </History.Date>
                <Arrow.ChevronRight />
              </Stack>
              <Text.P1Semibold>{session.description}</Text.P1Semibold>
            </Stack>
            <History.BottomBlur />
          </History.Card>
        ))}
      </Cards.MultiCardSection>
    </Stack>
  );
}

ECoachSessionList.propTypes = {
  navigate: PropTypes.func,
};
