import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// components
import ChatBubble from 'components/pageECoach/cardTemplates/ChatBubble';

// styles
import * as Page from 'components/pageECoach/_styles/ChatArea.style';
import * as Styled from 'components/pageECoach/_styles/ChatBubble.style';

function UserPrompt({ content, EditForm }) {
  const [isEditing, setIsEditing] = useState(!content);

  useEffect(() => {
    setIsEditing(!content);
  }, [content]);

  if (isEditing) {
    return (
      <EditForm
        handleSubmit={() => {
          setIsEditing(false);
        }}
      />
    );
  }

  return (
    <Page.UserSection>
      <ChatBubble pointer={true} sentByUser>
        <Styled.DescriptionText>{content}</Styled.DescriptionText>
      </ChatBubble>
    </Page.UserSection>
  );
}

export default UserPrompt;

UserPrompt.propTypes = {
  content: PropTypes.string,
  onEditTriggered: PropTypes.func,
  EditForm: PropTypes.func,
};
