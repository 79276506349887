import React from 'react';
import { useTranslation } from 'react-i18next';

// styles
import * as Styled from 'components/pageECoach/_styles/Divider.style';

function OrDivider() {
  const { t } = useTranslation('pageEcoach');
  return (
    <Styled.OrDivider>
      <Styled.OrDividerTextArea>
        <Styled.OrDividerText>{t('Or')}</Styled.OrDividerText>
      </Styled.OrDividerTextArea>
      <Styled.Divider />
    </Styled.OrDivider>
  );
}

export default OrDivider;
