import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import OrDivider from 'components/pageECoach/cardTemplates/OrDivider';
import ChatBubble from 'components/pageECoach/cardTemplates/ChatBubble';
import SubmitButton from 'components/pageECoach/cardTemplates/SubmitButton';

// context
import { useUser } from 'contexts/UserContext';

// styles
import colours from 'constants/colours';
import * as InputStyle from 'components/pageECoach/_styles/MessageInputs.style';

export default function DescriptionCard(props) {
  const {
    onSubmit,
    overview,
    openPromptHelper,
    openSampleCases,
    setPendingOverview,
  } = props;
  const { t } = useTranslation('pageEcoach');
  const { data: user } = useUser();
  const nickname = user.nickname || user.first_name;

  const header = t("What's a challenge you're facing at work?", {
    nickname,
  });

  const subheader = t(
    'The more details you give, the better your results will be. Always confidential.'
  );

  const showPromptHelper = () => openPromptHelper(true);
  const showSampleCases = () => openSampleCases(true);

  const textAreaPlaceholder = t(
    'I want to get my team member to take more initiative'
  );

  return (
    <ChatBubble header={header} subheader={subheader}>
      <InputStyle.InputTextArea
        id="outlined-multiline-static"
        multiline
        onChange={(input) => setPendingOverview(input.target.value)}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            if (overview.length !== 0) onSubmit();
            ev.preventDefault();
          }
        }}
        placeholder={textAreaPlaceholder}
        rows={8}
        sx={{
          fieldset: {
            borderRadius: '10px',
            border: `2px solid ${colours.neutralCool[200]}`,
          },
          maxWidth: '100%',
        }}
        value={overview}
      />
      <SubmitButton inactive={overview.length === 0} onSubmit={onSubmit} />
      {!overview && (
        <>
          <OrDivider />
          <InputStyle.ButtonsColumn>
            <InputStyle.PromptButton onClick={showPromptHelper}>
              {t('Not sure what to write?')}
            </InputStyle.PromptButton>
            {user.is_demo && (
              <InputStyle.PromptButton onClick={showSampleCases}>
                {t('sampleCases.Try out some examples')}
              </InputStyle.PromptButton>
            )}
          </InputStyle.ButtonsColumn>
        </>
      )}
    </ChatBubble>
  );
}

DescriptionCard.propTypes = {
  onSubmit: PropTypes.func,
  overview: PropTypes.string,
  openPromptHelper: PropTypes.func,
  openSampleCases: PropTypes.func,
  setPendingOverview: PropTypes.func,
};
