import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import { General } from 'untitledui-js';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/buttonFeedback/_styles/FeedbackCard.style';
import * as Text from 'components/_styles/Text.style';

function CheckMarkSelection({ response, setResponse }) {
  const { t } = useTranslation();
  const handleCheckClick = (input) => setResponse(input);

  return (
    <Styled.FormInput>
      <Buttons.MultipleChoice
        className={response === false && 'active'}
        onClick={() => handleCheckClick(false)}
      >
        <General.XClose />
        <Text.P2Semibold>{t('buttonFeedback:no')}</Text.P2Semibold>
      </Buttons.MultipleChoice>
      <Buttons.MultipleChoice
        className={response === true && 'active'}
        onClick={() => handleCheckClick(true)}
      >
        <General.Check />
        <Text.P2Semibold>{t('buttonFeedback:yes')}</Text.P2Semibold>
      </Buttons.MultipleChoice>
    </Styled.FormInput>
  );
}

CheckMarkSelection.propTypes = {
  response: PropTypes.bool,
  setResponse: PropTypes.func,
};

export default CheckMarkSelection;
