import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';

export const Navigation = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const ProgressSection = styled('div')`
  display: flex;
  gap: 10px;

  @media (max-width: ${device.mobileL}) {
    gap: 5px;
  }
`;

export const ProgressCell = styled('div')`
  background-color: ${colours.neutralCool[100]};
  border-radius: 20px;
  height: 9px;
  width: 9px;

  @media (max-width: ${device.mobileL}) {
    height: 6px;
    width: 6px;
  }

  &.active {
    background-color: ${colours.neutralCool[800]};
  }
`;

export const ButtonFiller = styled('div')`
  width: 60px;
`;
