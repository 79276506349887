const helperPrompts = [
  {
    category: 'Overcome a challenge in my team',
    options: [
      {
        label: 'boost team performance',
        prompt: 'steps I can take to overcome a team performance challenge.',
      },
      {
        label: 'manage difficult conversations',
        prompt:
          'how to effectively manage a complex situation with a team member.',
      },
      {
        label: 'resolve team conflicts',
        prompt: 'effective methods for resolving a team conflict.',
      },
      {
        label: 'support team members',
        prompt: "how to support a team member who's struggling.",
      },
      {
        label: 'improve team morale',
        prompt: "strategies for improving my team's morale.",
      },
    ],
  },
  {
    category: "Elevate my team's success",
    options: [
      {
        label: 'celebrate team success',
        prompt: "creative ways to recognize and reward my team's hard work.",
      },
      {
        label: 'improve team bonding',
        prompt:
          'which activities or practices can help build stronger team camaraderie.',
      },
    ],
  },
  {
    category: 'Grow as a leader',
    options: [
      {
        label: 'upgrade leadership skills',
        prompt:
          'what specific areas I should focus on to enhance my leadership skills.',
      },
      {
        label: 'handle leadership stress',
        prompt: 'practical techniques for managing stress as a leader.',
      },
      {
        label: 'balance work and life',
        prompt: 'how to effectively balance my workload and personal time.',
      },
    ],
  },
  {
    category: 'Improve workplace interactions',
    options: [
      {
        label: 'encourage cross-team collaboration',
        prompt:
          'some strategies for fostering collaboration between departments.',
      },
      {
        label: 'foster company culture',
        prompt:
          'what actions I can take to contribute to a more positive and inclusive company culture.',
      },
      {
        label: 'boost employee engagement',
        prompt:
          'what initiatives I can introduce to enhance employee satisfaction and engagement.',
      },
    ],
  },
];

export default helperPrompts;
