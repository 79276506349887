import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import { Arrow } from 'untitledui-js';

// styles
import * as Styled from 'components/buttonBooking/_styles/LanguageSelection.style';

function LanguageSelection({ languageSelection, setLanguageSelection }) {
  const { t } = useTranslation();

  const [showLanguageForm, setShowLanguageForm] = useState(false);

  const selectLanguage = (language) => setLanguageSelection(language);
  const openLanguageForm = () => setShowLanguageForm((prev) => !prev);
  const languageText = (language) =>
    language === 'en' ? 'English' : 'Français';

  return (
    <Styled.LanguageSection onClick={openLanguageForm}>
      <Styled.FormInputText>
        {t('buttonBooking:selectLanguage')}
      </Styled.FormInputText>
      {showLanguageForm ? (
        <Styled.LanguageOptions>
          <Styled.LanguageOption onClick={() => selectLanguage('en')}>
            <Styled.FormInputText>English</Styled.FormInputText>
          </Styled.LanguageOption>
          <Styled.LanguageOption onClick={() => selectLanguage('fr')}>
            <Styled.FormInputText>Français</Styled.FormInputText>
          </Styled.LanguageOption>
        </Styled.LanguageOptions>
      ) : (
        <Styled.Language>
          <Styled.FormInputText>
            {languageText(languageSelection)}
          </Styled.FormInputText>
          <Arrow.ChevronDown />
        </Styled.Language>
      )}
    </Styled.LanguageSection>
  );
}

export default LanguageSelection;

LanguageSelection.propTypes = {
  languageSelection: PropTypes.string,
  setLanguageSelection: PropTypes.func,
};
