import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import JoinLobbyButton from 'components/buttonJoinLobby/_index';

// constants
import routesConfig from 'constants/routesConfig.json';

// helpers
import checkIfLive from 'helpers/checkIfLive';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Button from 'components/_styles/Buttons.style';

function CTAs({ journey, microlearning, showEvalCard }) {
  const { t } = useTranslation();
  const history = useHistory();
  const lang = useUserLanguage();
  const [isLive, setIsLive] = useState(false);

  const handleRedirect = useCallback(() => {
    history.push({ pathname: `${routesConfig.JOURNEY_ID_PAGE}/${journey.id}` });
  }, [history, journey]);

  const goToEvaluationPage = useCallback(() => {
    history.push({
      pathname: `${routesConfig.EVALUATION_PAGE}`,
      state: {
        evaluationId: journey.evaluation_id,
        journeyId: journey.id,
        journeyTitle: journey[`title_${lang}`],
      },
    });
  }, [history, journey, lang]);

  const bookingStartsAt = microlearning?.booking_starts_at;

  useEffect(() => {
    if (checkIfLive(bookingStartsAt)) setIsLive(true);
  }, [bookingStartsAt, setIsLive]);

  if (showEvalCard)
    return (
      <Button.Primary onClick={goToEvaluationPage}>
        {t('pageJourneyList:evaluationCardButton')}
      </Button.Primary>
    );

  if (isLive)
    return (
      <JoinLobbyButton
        bookingId={microlearning.booking_id}
        journeyId={journey.id}
        microlearningId={microlearning.id}
        microlearningTitle={microlearning[`title_${lang}`]}
      />
    );

  return (
    <Button.Secondary onClick={handleRedirect}>
      {t('pageJourneyList:details')}
    </Button.Secondary>
  );
}

CTAs.propTypes = {
  journey: PropTypes.object,
  microlearning: PropTypes.object,
  showEvalCard: PropTypes.bool,
};

export default CTAs;
