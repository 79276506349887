import { useCallback, useState } from 'react';

function parseMultipart(blob, boundary) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const decoder = new TextDecoder();
      const text = decoder.decode(arrayBuffer);
      const parts = text.split(`--${boundary}`);

      const result = {};
      parts.forEach((part) => {
        if (part.includes('Content-Disposition')) {
          const [headers, body] = part.split('\r\n\r\n');
          if (headers.includes('name="metadata"')) {
            result.metadata = JSON.parse(body);
          } else if (headers.includes('name="file"')) {
            const startIndex = text.indexOf('\r\n\r\n') + 4; // Find the start of binary data
            const binaryData = arrayBuffer.slice(
              startIndex,
              arrayBuffer.byteLength - boundary.length - 8
            );
            result.pdf = new Blob([binaryData], { type: 'application/pdf' });
          }
        }
      });

      resolve(result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsArrayBuffer(blob);
  });
}

const useApi = () => {
  const [data, setData] = useState({ status: 'loading', response: null });
  const authorization = localStorage.getItem('authorization');
  const authorizationToken = authorization
    ? `Token ${JSON.parse(authorization).token}`
    : null;

  const callApi = useCallback(
    (path = '', method = 'get', params = null, additionalHeaders = null) => {
      const url = process.env.REACT_APP_API_URL + path;
      let headers =
        authorizationToken && path !== '/login/'
          ? {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: authorizationToken,
            }
          : {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            };

      headers = { ...(additionalHeaders || {}), ...headers };

      let requestOptions;

      switch (method) {
        case 'post':
          requestOptions = {
            headers,
            method: 'POST',
            body: JSON.stringify(params),
          };
          fetch(url, requestOptions).then((response) => {
            if (response.status === 204) {
              setData({ status: 'success', response: {} });
            } else if (response.ok) {
              response
                .json()
                .then((formattedData) =>
                  setData({ status: 'success', response: formattedData })
                );
            } else {
              setData({ status: 'error', response });
            }
          });
          break;
        case 'pdf':
          requestOptions = {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: authorizationToken,
            },
            method: 'GET',
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              const boundary = response.headers
                .get('Content-Type')
                .match(/boundary=(.*)$/)[1];

              response.blob().then((blob) => {
                parseMultipart(blob, boundary).then((parsedBlob) => {
                  const pdfUrl = window.URL.createObjectURL(parsedBlob.pdf);
                  setData({
                    status: 'success',
                    response: { pdfUrl, metadata: parsedBlob.metadata },
                  });
                });
              });
            } else {
              setData({ status: 'error', response });
            }
          });
          break;
        case 'picture':
          requestOptions = {
            headers: {
              Accept: 'application/json',
              Authorization: authorizationToken,
            },
            method: 'POST',
            body: params,
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              setData({ status: 'success', response });
            } else {
              setData({ status: 'error', response });
            }
          });
          break;
        case 'put':
          requestOptions = {
            headers,
            method: 'PUT',
            body: JSON.stringify(params),
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              response
                .json()
                .then((formattedData) =>
                  setData({ status: 'success', response: formattedData })
                );
            } else {
              setData({ status: 'error', response });
            }
          });
          break;
        case 'delete':
          requestOptions = {
            headers,
            method: 'DELETE',
            body: JSON.stringify(params),
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              setData({ status: 'success', response });
            } else {
              setData({ status: 'error', response });
            }
          });
          break;
        default:
          requestOptions = {
            headers,
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              response
                .json()
                .then((formattedData) =>
                  setData({ status: 'success', response: formattedData })
                );
            } else {
              setData({ status: 'error', response });
            }
          });
          break;
      }
      return null;
    },
    [authorizationToken]
  );

  return { data, callApi };
};

export default useApi;
