import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { ReactComponent as GreenCheckIcon } from 'assets/green-check.svg';

// context
import { useUser } from 'contexts/UserContext';

// helpers
import supportLink from 'helpers/supportLink';

// styles
import * as InputStyle from 'components/pageECoach/_styles/MessageInputs.style';
import * as Styled from 'components/pageECoach/_styles/AboutECoach.style';
import * as Text from 'components/_styles/Text.style';

function IntroCard({ onPrivacyClick, toggleExplainer }) {
  const { t } = useTranslation('pageEcoach');
  const { data: user } = useUser();

  const instructionRows = [
    t('aboutECoach.intro.instruction1'),
    t('aboutECoach.intro.instruction2'),
    t('aboutECoach.intro.instruction3'),
  ];

  const getRow = (row, key) => (
    <Styled.InstructionRow key={key}>
      <GreenCheckIcon />
      <Text.P2>{row}</Text.P2>
    </Styled.InstructionRow>
  );

  const onHelpClick = () => window.open(supportLink(user.language), '_blank');

  return (
    <>
      <Styled.Content>
        <Styled.AboutSection>
          <Styled.AboutDescription>
            <Text.P2Semibold>
              {t('aboutECoach.intro.subheader1')}
            </Text.P2Semibold>
            <Text.P2>{t('aboutECoach.intro.about1')}</Text.P2>
            <Text.P2>{t('aboutECoach.intro.about2')}</Text.P2>
          </Styled.AboutDescription>
        </Styled.AboutSection>
        <Styled.InstructionSection>
          <Text.H6Bold>{t('aboutECoach.intro.subheader2')}</Text.H6Bold>
          {instructionRows.map((row, key) => getRow(row, key))}
        </Styled.InstructionSection>
      </Styled.Content>
      <Styled.ActionArea>
        <InputStyle.PromptButton onClick={onPrivacyClick}>
          {t('aboutECoach.intro.privacy')}
        </InputStyle.PromptButton>
        <InputStyle.PromptButton onClick={onHelpClick}>
          {t('aboutECoach.intro.help')}
        </InputStyle.PromptButton>
        <InputStyle.PromptButton onClick={toggleExplainer}>
          {t('aboutECoach.hide')}
        </InputStyle.PromptButton>
      </Styled.ActionArea>
    </>
  );
}

IntroCard.propTypes = {
  onPrivacyClick: PropTypes.func,
  toggleExplainer: PropTypes.func,
};

export default IntroCard;
