import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';

export const Slide = styled('div')`
  aspect-ratio: 16 / 9;
  background-color: ${colours.shade[0]};
  border-radius: 30px;
  box-sizing: border-box;
  box-shadow: ${shadows.L};
  display: flex;
  gap: 30px;
  justify-content: left;
  max-height: 70%;
  position: relative;

  @media (max-width: ${device.tablet}) {
    border-radius: 0;
  }
`;

export const SlideVideoEmbed = styled('video')`
  border-radius: 30px;
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    border-radius: 0;
  }
`;

export const SlideImage = styled('img')`
  background-color: ${colours.shade[0]};
  border-radius: 30px;
  box-shadow: ${shadows.L};
  box-sizing: border-box;
  position: relative;
  object-fit: cover;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    border-radius: 0;
  }
`;

export const Titles = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 60px 0 0 60px;
  position: absolute;

  h2,
  p {
    color: ${colours.shade[0]};
  }

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`;
