import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// components
import MicrolearningCardButtons from 'components/pageJourneyId/memberView/MicrolearningCardButtons';
import MicrolearningCardDate from 'components/pageJourneyId/memberView/MicrolearningCardDate';
import MicrolearningCardStatus from 'components/pageJourneyId/memberView/MicrolearningCardStatus';

// helpers
import checkIfLive from 'helpers/checkIfLive';
import isFinished from 'helpers/isFinished';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyId/_styles/MicrolearningCard.style';
import * as Text from 'components/_styles/Text.style';

function MicrolearningCardMember({ cover, journey, microlearning }) {
  const lang = useUserLanguage();
  const [isLive, setIsLive] = useState(false);

  const openFeedbackForm = () => {
    const urlQuery = window.location.href.split('open_feedback_form=')[1];
    if (urlQuery) return urlQuery === microlearning.id;
    return null;
  };

  const hasBooking = microlearning.booking_starts_at !== null;
  const wasAttended =
    microlearning.microlearning_completed_at !== null ||
    microlearning.offlive_completed_at !== null;
  const wasReviewed = microlearning.reviewed;
  const isOver = isFinished(microlearning.booking_starts_at);
  const microlearningTitle = microlearning[`title_${lang}`];
  const microlearningDescription = microlearning[`description_${lang}`];

  useEffect(() => {
    if (checkIfLive(microlearning.booking_starts_at)) setIsLive(true);
  }, [microlearning, setIsLive]);

  return (
    <Styled.Row>
      <Styled.StatusContainer>
        {hasBooking && <MicrolearningCardStatus wasReviewed={wasReviewed} />}
      </Styled.StatusContainer>
      <Styled.Card>
        <Styled.Cover>
          <Styled.CoverImage src={cover} />
        </Styled.Cover>
        <Styled.InfoSection>
          {microlearningTitle && (
            <Text.H5Bold>{microlearningTitle}</Text.H5Bold>
          )}
          <Styled.Details>
            {hasBooking && (
              <MicrolearningCardDate
                isLive={isLive}
                isOver={isOver}
                microlearning={microlearning}
                wasAttended={wasAttended}
              />
            )}
            {microlearningDescription && (
              <Text.P2Semibold>{microlearningDescription}</Text.P2Semibold>
            )}
          </Styled.Details>
          <MicrolearningCardButtons
            cover={cover}
            isLive={isLive}
            isOver={isOver}
            journey={journey}
            microlearning={microlearning}
            openFeedbackForm={openFeedbackForm()}
            wasAttended={wasAttended}
            wasReviewed={wasReviewed}
          />
        </Styled.InfoSection>
      </Styled.Card>
    </Styled.Row>
  );
}

MicrolearningCardMember.propTypes = {
  cover: PropTypes.string,
  journey: PropTypes.object,
  microlearning: PropTypes.object,
};

export default MicrolearningCardMember;
