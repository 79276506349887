import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';
import * as PageLayout from 'components/_pageLayout/_styles/PageLayout.style';

export const Page = styled(PageLayout.Page)`
  gap: 0;
  padding: 0;

  @media (max-width: ${device.tablet}) {
    gap: 15px;
  }
`;

export const PageContent = styled('div')`
  border-radius: 0;
  width: 100%;
`;

export const Containter = styled('div')`
  border-radius: 50px;
  box-shadow: ${shadows.L};
  display: flex;
  height: 740px;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
    height: 664px;
  }
`;

export const Cover = styled('div')`
  flex: 1;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;

  @media (max-width: ${device.tablet}) {
    max-height: 100%;
  }
`;

export const CoverImage = styled('img')`
  height: 100%;
  inset: 0;
  object-fit: cover;
  width: 100%;
`;

export const Header = styled('div')`
  align-items: flex-start;
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 670px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  @media (max-width: ${device.tablet}) {
    height: 300px;
  }
`;

export const HeaderButton = styled('div')`
  align-items: center;
  background-color: ${colours.neutralCool[50]};
  border-radius: 40px;
  display: flex;
  gap: 10px;
  justify-content: center;
  max-height: 44px;
  max-width: 44px;
  padding: 10px;
  z-index: 10;

  svg path {
    stroke: ${colours.neutralCool[800]};
  }
`;

export const BackButton = styled(HeaderButton)`
  cursor: pointer;
  display: flex;
`;

export const CloseButton = styled(HeaderButton)`
  display: none;

  @media (max-width: ${device.tablet}) {
    display: flex;
  }
`;

export const HeaderContent = styled('div')`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: space-between;
  padding: 60px;

  @media (max-width: ${device.tablet}) {
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-end;
    padding: 30px;
  }
`;

export const Top = styled('div')`
  gap: 10px;
  padding: 60px;
  z-index: 10;

  @media (max-width: ${device.tablet}) {
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-end;
    padding: 30px;
  }
`;

export const InfoSection = styled('div')`
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 60px;
  width: 100%;
  z-index: 2;

  h1 {
    color: ${colours.shade[0]};
  }

  p {
    color: ${colours.shade[0]};
  }

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
    gap: 10px;
    padding: 30px;
  }
`;

export const TabContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: ${device.tablet}) {
    padding: 0;
  }
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 60px 0;

  @media (max-width: ${device.tablet}) {
    gap: 30px;
    padding: 15px;
  }
`;

export const CtaSection = styled('div')`
  align-items: center;
  display: flex;
  gap: 30px;
  width: 187px;
  z-index: 2;

  @media (max-width: ${device.tablet}) {
    width: 100%;
  }
`;
