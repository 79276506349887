import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routesConfig from 'constants/routesConfig.json';

// assets & styles
import { Arrow } from 'untitledui-js';
import * as Header from 'components/pageECoach/_styles/Header.style';

export default function ReturnToSessionButton({ session }) {
  const { t } = useTranslation('pageEcoach');
  const history = useHistory();

  const redirectToSession = useCallback(() => {
    history.push(`${routesConfig.E_COACH_PAGE}/${session}`);
  }, [history, session]);

  return (
    <Header.HeaderButton
      onClick={redirectToSession}
      style={{ paddingRight: '15px' }}
    >
      <Arrow.ChevronLeft size={'15'} />
      {t('Return to chat')}
    </Header.HeaderButton>
  );
}

ReturnToSessionButton.propTypes = {
  session: PropTypes.string,
};
