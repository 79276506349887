import React from 'react';
import PropTypes from 'prop-types';

// components
import ActiveCardMember from 'components/pageJourneyList/memberView/ActiveCardMember';
import InactiveCard from 'components/pageJourneyList/InactiveCard';

// styles
import * as Styled from 'components/pageJourneyList/_styles/PageView.style';

export default function MemberJourneyList({ journeyList }) {
  const checkIfActive = (journey) =>
    journey.microlearnings[0].booking_id !== null &&
    journey.evaluation_completed_at === null;

  const checkIfInactive = (journey) =>
    journey.microlearnings[0].booking_id === null ||
    journey.evaluation_completed_at !== null;

  const activeJourneys = journeyList.filter(checkIfActive);
  const inactiveJourneys = journeyList.filter(checkIfInactive);

  const getInactiveJourneyView = (journey) => {
    if (journey.evaluation_completed_at !== null)
      return <InactiveCard journey={journey} memberCompleted={true} />;
    return <InactiveCard journey={journey} />;
  };

  return (
    <Styled.Page>
      {activeJourneys.map((journey) => (
        <div key={journey.id}>
          <ActiveCardMember journey={journey} />
        </div>
      ))}
      {inactiveJourneys.map((journey) => (
        <div key={journey.id}>{getInactiveJourneyView(journey)}</div>
      ))}
    </Styled.Page>
  );
}

MemberJourneyList.propTypes = {
  journeyList: PropTypes.array,
};
