import styled from 'styled-components';
import * as Cards from 'components/_styles/Cards.style';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';

export const Card = styled(Cards.BasicCard)`
  border-radius: 40px;
  box-shadow: ${shadows.M};
  box-sizing: border-box;
  flex-direction: unset;
  flex-grow: 1;
  gap: 10px;
  justify-content: unset;
  max-width: unset;
  padding: 40px;
  width: unset;

  @media (max-width: ${device.mobileL}) {
    padding: 0;
  }
`;

export const InnerContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  padding: 20px;
`;

export const Participant = styled('div')`
  align-items: center;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 8px;
`;

export const Avatar = styled('div')`
  align-items: center;
  border-radius: 100px;
  display: flex;
  height: 72px;
  justify-content: center;
  width: 72px;
`;
