import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import TextField from '@mui/material/TextField';
import SubmitButton from 'components/pageECoach/cardTemplates/SubmitButton';

// style
import * as PromptHelperStyle from 'components/pageECoach/_styles/PromptHelper.style';
import * as Text from 'components/_styles/Text.style';

export default function FillInTheBlanksCard(props) {
  const { onSubmit, selectedPrompt, setPendingOverview } = props;
  const { t } = useTranslation('pageEcoach');
  const [challenge, setChallenge] = useState(selectedPrompt);
  const [details, setDetails] = useState('');
  const [goal, setGoal] = useState('');
  const [report, setReport] = useState('');

  // Configure overview text
  const header = selectedPrompt
    ? t(
        "We've filled in the first blank for you based on your choice. Please fill in the other blanks for the best results!"
      )
    : t('Please fill in the blanks for the best results.');
  const challengeText = t("The challenge I'm facing at work is figuring out");
  const detailsText = t('In my specific case');
  const goalText = t('My goal is to');
  const reportText = t("I'm looking for");

  useEffect(() => {
    setChallenge(selectedPrompt);
  }, [selectedPrompt]);

  useEffect(() => {
    const challengeSetup = challenge ? `${challengeText} ${challenge}. ` : '';
    const detailsSetup = details ? `${detailsText}, ${details}. ` : '';
    const goalSetup = goal ? `${goalText} ${goal}. ` : '';
    const reportSetup = report ? `${reportText} ${report}.` : '';
    const fullPrompt = `${challengeSetup}${detailsSetup}${goalSetup}${reportSetup}`;
    setPendingOverview(fullPrompt.replaceAll('..', '.'));
  }, [
    challenge,
    challengeText,
    details,
    detailsText,
    goal,
    goalText,
    report,
    reportText,
    setPendingOverview,
  ]);

  return (
    <PromptHelperStyle.FillInTheBlanksSection id="fill-in-blanks">
      <Text.P2Semibold>{header}</Text.P2Semibold>
      <TextField
        id="outlined-required"
        InputLabelProps={{ shrink: true }}
        label={`${challengeText}...`}
        multiline
        onChange={(i) => setChallenge(i.target.value)}
        placeholder={t(
          'promptHelper.effective methods for resolving a team conflict.'
        )}
        required
        value={challenge}
        variant="standard"
      />
      <TextField
        id="outlined-required"
        label={`${detailsText}...`}
        placeholder={t('e.g., two people on my team are butting heads.')}
        onChange={(i) => setDetails(i.target.value)}
        variant="standard"
        InputLabelProps={{ shrink: true }}
        multiline
      />
      <TextField
        id="outlined-required"
        label={`${goalText}...`}
        placeholder={t('e.g., make sure everyone is getting along')}
        onChange={(i) => setGoal(i.target.value)}
        variant="standard"
        InputLabelProps={{ shrink: true }}
        multiline
      />
      <TextField
        id="outlined-required"
        label={`${reportText}...`}
        placeholder={t('e.g., resources, advice, ideas on...')}
        onChange={(i) => setReport(i.target.value)}
        variant="standard"
        InputLabelProps={{ shrink: true }}
        multiline
      />
      <SubmitButton inactive={challenge.length === 0} onSubmit={onSubmit} />
    </PromptHelperStyle.FillInTheBlanksSection>
  );
}

FillInTheBlanksCard.propTypes = {
  onSubmit: PropTypes.func,
  selectedPrompt: PropTypes.string,
  setPendingOverview: PropTypes.func,
};
