import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// assets
import FinalCardImg from 'assets/finalCard.svg';

// component
import Textbox from 'components/pageSlideshow/Textbox';

// constants
import routesConfig from 'constants/routesConfig.json';

// styles
import * as StyledTextbox from 'components/pageSlideshow/_styles/Textbox.style';

export default function SlideLast({ journeyId, microlearningId, wasReviewed }) {
  const history = useHistory();

  const handleRedirect = () => {
    const needsReview = !wasReviewed
      ? `?open_feedback_form=${microlearningId}`
      : '';
    history.push({
      pathname: `${routesConfig.JOURNEY_ID_PAGE}/${journeyId}${needsReview}`,
    });
  };

  return (
    <>
      <StyledTextbox.SuccessImgContainer>
        <StyledTextbox.SuccessImg src={FinalCardImg} />
      </StyledTextbox.SuccessImgContainer>
      <Textbox
        ctaView={true}
        nextClick={handleRedirect}
        wasReviewed={wasReviewed}
      />
    </>
  );
}

SlideLast.propTypes = {
  journeyId: PropTypes.string,
  microlearningId: PropTypes.string,
  wasReviewed: PropTypes.bool,
};
