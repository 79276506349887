import styled from 'styled-components';
import device from 'constants/devices';

export const Page = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

export const Header = styled('div')`
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 20px;
  position: relative;
  top: 0;
  width: 100%;
`;

export const Slideshow = styled('div')`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 10px;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    padding: 0;
  }
`;

export default Page;
