import styled from 'styled-components';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';
import colours from 'constants/colours';
import device from 'constants/devices';

export const Card = styled(Cards.Card)`
  cursor: pointer;
  flex: 1 1 47%;
  height: 150px;
  max-width: 47%;
  overflow: hidden;
  width: 47%;

  @media (max-width: ${device.tablet}) {
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
  }
`;

export const BottomBlur = styled('div')`
  background-image: linear-gradient(
    rgba(255, 0, 0, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 1)
  );
  bottom: 0;
  height: 60px;
  left: 0;
  position: absolute;
  width: 100%;
`;

export const Date = styled(Text.P1Semibold)`
  color: ${colours.neutralCool[600]} !important;
`;
