import React from 'react';
import PropTypes from 'prop-types';

// assets
import { Arrow, General } from 'untitledui-js';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/buttonFeedback/_styles/Navigation.style';

function Navigation(props) {
  const {
    backClick,
    currentCard,
    mandatoryAnswer,
    nextClick,
    numQuestions,
    response,
  } = props;
  const isActive = (num) => (currentCard === num ? 'active' : '');

  const getFeedbackClick = () => {
    if (mandatoryAnswer && !response && response !== false)
      return (
        <Buttons.PrimaryIconDisabled>
          <Arrow.ChevronRight />
        </Buttons.PrimaryIconDisabled>
      );

    return (
      <Buttons.PrimaryIcon onClick={nextClick}>
        {currentCard === numQuestions - 1 ? (
          <General.Check />
        ) : (
          <Arrow.ChevronRight />
        )}
      </Buttons.PrimaryIcon>
    );
  };

  return (
    <Styled.Navigation>
      {currentCard === 0 ? (
        <Styled.ButtonFiller />
      ) : (
        <Buttons.SecondaryIcon onClick={backClick}>
          <Arrow.ChevronLeft />
        </Buttons.SecondaryIcon>
      )}
      <Styled.ProgressSection>
        {[...Array(numQuestions)].map((e, i) => (
          <Styled.ProgressCell className={isActive(i)} key={i} />
        ))}
      </Styled.ProgressSection>
      {getFeedbackClick()}
    </Styled.Navigation>
  );
}

Navigation.propTypes = {
  backClick: PropTypes.func,
  currentCard: PropTypes.number,
  mandatoryAnswer: PropTypes.bool,
  nextClick: PropTypes.func,
  numQuestions: PropTypes.number,
  response: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default Navigation;
