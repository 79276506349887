import styled from 'styled-components';
import colours from 'constants/colours';
import * as Text from 'components/_styles/Text.style';

export const Divider = styled('div')`
  border-bottom: 0.5px ${colours.neutralCool[300]} solid;
  width: 100%;
`;

export const OrDivider = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const OrDividerTextArea = styled('div')`
  background-color: ${colours.shade[0]};
  padding: 0 20px;
  position: absolute;
  width: fit-content;
`;

export const OrDividerText = styled(Text.CaptionSemiboldCap)`
  color: ${colours.neutralCool[300]} !important;
  position: relative;
`;
