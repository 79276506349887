import React from 'react';
import PropTypes from 'prop-types';
import * as Cards from 'components/pageECoach/_styles/ChatBubble.style';
import { LoadingStatus } from 'hooks/useEcoachItemsConsumer';
import * as Text from 'components/_styles/Text.style';

export default function ECoachResponse({
  status,
  loadingTitle,
  loader,
  children,
}) {
  return (
    <Cards.ChatBubble>
      {status === LoadingStatus.LOADED ? (
        children
      ) : (
        <>
          <Text.SubheadingSemibold>{loadingTitle}</Text.SubheadingSemibold>
          {loader}
        </>
      )}
    </Cards.ChatBubble>
  );
}

ECoachResponse.propTypes = {
  status: PropTypes.string.isRequired,
  loadingTitle: PropTypes.string.isRequired,
  loader: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};
