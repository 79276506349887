import { styled } from '@mui/material/styles';
import OwlAvatar from 'components/pageOnboarding/assets/owl-avatar.svg';

export const ClockIcon = styled('img')`
  height: 20px;
`;

export const OwlImageContainer = styled('div')`
  background-image: url(${OwlAvatar});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 100px;
  height: 30px;
  object-fit: cover;
  width: 30px;
`;

export const OwlImage = styled('img')`
  height: 100%;
  width: 100%;
`;

export const Participants = styled('div')`
  align-items: center;
  display: flex;
  gap: 13px;
  padding: 5px 10px 5px 5px;
`;

export const SessionSection = styled('div')`
  align-items: center;
  display: flex;
  gap: 13px;
  padding: 5px 10px 5px 5px;
`;

export const UsersIcon = styled('img')`
  height: 20px;
`;
