import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// components
import ChatBubble from 'components/pageECoach/cardTemplates/ChatBubble';
import ConversationPlanCard from 'components/pageECoach/conversationPlan/ConversationPlanCard';
import ConversationPlanForm from 'components/pageECoach/conversationPlan/ConversationPlanForm';
import ECoachChallengeVerification from 'components/pageECoach/ECoachChallengeVerification';
import OverviewForm from 'components/pageECoach/overviewForm/_index';
import SuggestionsCard from 'components/pageECoach/suggestions/SuggestionsCard';
import UserPrompt from 'components/pageECoach/actions/UserPrompt';
import { Stack } from '@mui/material';

// contexts
import { useSessionContext } from 'contexts/ECoachSessionContext';
import { useUser } from 'contexts/UserContext';

// helpers
import {
  SessionProgressChoices,
  UserFeatureFlagType,
} from 'components/pageECoach/utils';

// styles
import * as Page from 'components/pageECoach/_styles/ChatArea.style';
import * as Text from 'components/_styles/Text.style';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export default function ECoachSession() {
  const { session } = useSessionContext();
  const { t } = useTranslation('pageEcoach');

  useEffect(() => {
    console.log('Session updated:', session);
  }, [session]);

  const { data: user } = useUser();
  const nickname = user.nickname || user.first_name;
  const isChallengeClarificationEnabled = useFeatureFlagEnabled(
    UserFeatureFlagType.ECOACH_CHALLENGE_CLARIFICATION
  );

  const renderSession = () => {
    if (!session) {
      return <UserPrompt key="overview_form" EditForm={OverviewForm} />;
    }

    const sessionPageview = [];

    sessionPageview.push(
      <Page.ECoachSection>
        <ChatBubble pointer={true}>
          <Text.P2Semibold>
            {t("What's a challenge you're facing at work?", {
              nickname,
            })}
          </Text.P2Semibold>
        </ChatBubble>
      </Page.ECoachSection>
    );

    if (
      isChallengeClarificationEnabled &&
      session.progress >= SessionProgressChoices.CHALLENGE_VERIFICATION
    ) {
      sessionPageview.push(
        <ECoachChallengeVerification key="challenge_verification" />
      );
    } else {
      // TODO Ensure challenge verification keeps track of the first input (and remove this one) once it is publicly available
      sessionPageview.push(
        <UserPrompt
          EditForm={OverviewForm}
          key={session?.context?.description}
          content={session?.context?.description}
        />
      );
    }

    if (session.progress >= SessionProgressChoices.SUGGESTIONS) {
      sessionPageview.push(<SuggestionsCard key="suggestions_card" />);
    }

    if (session.progress >= SessionProgressChoices.CONVERSATION_PREP) {
      sessionPageview.push(
        <UserPrompt
          content={session?.context?.convo_prep?.prompt}
          key={session?.context?.convo_prep?.prompt}
          EditForm={ConversationPlanForm}
        />
      );
    }

    if (session.progress >= SessionProgressChoices.CONVERSATION_PLAN) {
      sessionPageview.push(<ConversationPlanCard key="convo_prep" />);
    }

    return (
      <Stack spacing={8} style={{ marginTop: 'auto' }}>
        {sessionPageview}
      </Stack>
    );
  };

  return <>{renderSession()}</>;
}

ECoachSession.propTypes = {};
