import React from 'react';
import PropTypes from 'prop-types';

// assets
import { Shapes } from 'untitledui-js';

// styles
import * as Styled from 'components/buttonFeedback/_styles/FeedbackCard.style';

function StarSelection({ response, setResponse }) {
  const handleStarClick = (input) => setResponse(Number(input));

  const getStar = (num) => {
    if (response && response >= num) {
      return (
        <Styled.Star
          className="filled"
          onClick={() => handleStarClick(`${num}`)}
        >
          <Shapes.Star01 />
        </Styled.Star>
      );
    }
    return (
      <Styled.Star onClick={() => handleStarClick(`${num}`)}>
        <Shapes.Star01 />
      </Styled.Star>
    );
  };

  return (
    <Styled.Stars>
      {getStar(1)}
      {getStar(2)}
      {getStar(3)}
      {getStar(4)}
      {getStar(5)}
    </Styled.Stars>
  );
}

StarSelection.propTypes = {
  response: PropTypes.number,
  setResponse: PropTypes.func,
};

export default StarSelection;
