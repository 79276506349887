import React from 'react';
import PropTypes from 'prop-types';

// assets
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as LogoText } from 'assets/logoText.svg';
import { ReactComponent as GreenCheckIcon } from 'assets/green-check.svg';

// components
import ChatBubble from 'components/pageECoach/cardTemplates/ChatBubble';
import SuggestionCards from 'components/pageDemoRegistration/introCard/SuggestionCards';

// styles
import * as Styled from 'components/pageDemoRegistration/_styles/IntroCard.style';
import * as ChatBubbleStyled from 'components/pageECoach/_styles/ChatBubble.style';
import * as Text from 'components/_styles/Text.style';

export default function IntroCard({ t }) {
  const instructionRows = [
    t('Describe a workplace scenario you want advice on'),
    t('Discuss it with your eCoach'),
    t('Receive a custom plan with practical approaches'),
  ];

  const scrollToForm = () => {
    const form = document.getElementById('registration');
    form.scrollIntoView({ behavior: 'smooth' });
  };

  const topBar = () => (
    <Styled.TopBar>
      <Styled.LogoText>
        <LogoText />
      </Styled.LogoText>
      <Styled.LogoIcon>
        <Logo />
      </Styled.LogoIcon>
      <Text.P1Semibold>{t('eCoach Demo')}</Text.P1Semibold>
    </Styled.TopBar>
  );

  const headlineBlock = () => (
    <Styled.Block>
      <Text.H2Bold>{t('AI-powered coaching for managers')}</Text.H2Bold>
      <Text.P3>
        {t(
          "Based on your company policies and the latest science, Nurau's eCoach is designed to offer you tailor-made strategies to resolve complex workplace dynamics, empowering you to take decisive action when it matters most."
        )}
      </Text.P3>
    </Styled.Block>
  );

  const howToBlock = () => (
    <Styled.Block>
      <Text.H4Bold>{t('Collaborate with your eCoach')}</Text.H4Bold>
      <Styled.HowToRows>
        {instructionRows.map((row, key) => (
          <Styled.HowToRow key={key}>
            <GreenCheckIcon />
            <Styled.HowToText>
              <Text.P3>{row}</Text.P3>
            </Styled.HowToText>
          </Styled.HowToRow>
        ))}
      </Styled.HowToRows>
    </Styled.Block>
  );

  const eCoachBlock = () => {
    const userPrompt = () => (
      <ChatBubbleStyled.EditInputSection>
        <ChatBubbleStyled.UserBubbleWithPointer>
          <Styled.TemplateText>
            <ChatBubbleStyled.DescriptionText>
              {t('What should I do when ...')}
            </ChatBubbleStyled.DescriptionText>
            <Styled.TemplateTextRow />
            <Styled.TemplateTextRow />
          </Styled.TemplateText>
        </ChatBubbleStyled.UserBubbleWithPointer>
      </ChatBubbleStyled.EditInputSection>
    );
    const userPrompt2 = () => (
      <ChatBubbleStyled.EditInputSection>
        <ChatBubbleStyled.UserBubbleWithPointer>
          <Styled.PromptButton>
            <Text.P1Semibold>{t('Offer me more suggestions')}</Text.P1Semibold>
          </Styled.PromptButton>
          <Styled.PromptButton>
            <Text.P1Semibold>
              {t('Discuss my situation with me')}
            </Text.P1Semibold>
          </Styled.PromptButton>
        </ChatBubbleStyled.UserBubbleWithPointer>
      </ChatBubbleStyled.EditInputSection>
    );

    return (
      <Styled.ECoachCard>
        <Styled.ECoachLogo>
          <Logo />
        </Styled.ECoachLogo>
        <Styled.ChatSection type="eCoach">
          <ChatBubble pointer={true}>
            <Styled.TextBox>
              <Text.P2Semibold>
                {t('What’s a challenge you’re facing at work?')}
              </Text.P2Semibold>
              <Text.P1>
                {t(
                  'The more details you give, the better your results will be. Always confidential.'
                )}
              </Text.P1>
            </Styled.TextBox>
          </ChatBubble>
        </Styled.ChatSection>
        <Styled.ChatSection type="user">{userPrompt()}</Styled.ChatSection>
        <Styled.ChatSection type="eCoach">
          <ChatBubble pointer={true}>
            <Styled.TextBox>
              <Text.P2Semibold>
                {t('Here are some suggestions for you')}
              </Text.P2Semibold>
            </Styled.TextBox>
          </ChatBubble>
        </Styled.ChatSection>
        <SuggestionCards t={t} />
        <Styled.ChatSection type="user">{userPrompt2()}</Styled.ChatSection>
      </Styled.ECoachCard>
    );
  };

  return (
    <Styled.Card>
      {topBar()}
      <Styled.Content>
        <Styled.LeftSide>
          {headlineBlock()}
          {howToBlock()}
          <Styled.TryItButton onClick={scrollToForm}>
            {t('Try the free demo')}
          </Styled.TryItButton>
        </Styled.LeftSide>
        <Styled.RightSide>{eCoachBlock()}</Styled.RightSide>
      </Styled.Content>
    </Styled.Card>
  );
}

IntroCard.propTypes = {
  t: PropTypes.func.isRequired,
};
