import React, { useEffect, useState } from 'react';

// assets & components
import AboutButton from 'components/pageECoach/header/AboutButton';
import AboutECoachCard from 'components/pageECoach/aboutECoach/_index';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import ECoachSessionList from 'components/pageECoach/ECoachSessionList';
import ECoachSession from 'components/pageECoach/ECoachSession';
import FeedbackForm from 'components/pageECoach/FeedbackForm';
import HomeButton from 'components/pageECoach/header/HomeButton';
import UserInterviewBooking from 'components/pageECoach/UserInterviewBooking';
import { Stack } from '@mui/material';

// context
import { useUser } from 'contexts/UserContext';
import {
  ECoachSessionProvider,
  useSessionContext,
} from 'contexts/ECoachSessionContext';
import { LoadingStatus, useFeedbackConsumer } from 'hooks/useFeedbackConsumer';

// styles
import * as Styled from 'components/pageECoach/_styles/Header.style';

function ECoach() {
  const { session, retrieveSession } = useSessionContext();

  const { feedbackStatus, fetchFeedbackStatus, tryCreate } =
    useFeedbackConsumer();

  const { data: user } = useUser();
  const [showExplainer, setShowExplainer] = useState(user.is_demo);
  const toggleExplainer = () => setShowExplainer((prev) => !prev);

  const handleBack = () => {
    fetchFeedbackStatus(session.id);
  };

  useEffect(() => {
    if (feedbackStatus.loadingStatus !== LoadingStatus.LOADED) {
      return;
    }

    // TODO: Use an async function in handleBack to decouple feedback status and return to home behaviour
    if (!feedbackStatus.data.feedback_needed) {
      retrieveSession(null);
    }
  }, [feedbackStatus, retrieveSession]);

  return (
    <>
      <Styled.HeaderSection>
        {session && <HomeButton action={() => handleBack()} />}
        <AboutButton action={toggleExplainer} />
      </Styled.HeaderSection>
      <AboutECoachCard
        showExplainer={showExplainer}
        toggleExplainer={toggleExplainer}
      />
      <Stack spacing={8}>
        <FeedbackForm
          handleCreate={(data) => {
            tryCreate(session.id, data);
            retrieveSession(null);
          }}
          feedbackStatus={feedbackStatus}
        />
        <ECoachSession />
        {!session && !user.is_demo && <UserInterviewBooking />}
        {!session && <ECoachSessionList navigate={retrieveSession} />}
      </Stack>
    </>
  );
}

export default function ECoachPage() {
  return (
    <ErrorBoundary>
      <ECoachSessionProvider>
        <ECoach />
      </ECoachSessionProvider>
    </ErrorBoundary>
  );
}
