import PropTypes from 'prop-types';
import React from 'react';

// assets
import { Communication } from 'untitledui-js';

// styles
import colours from 'constants/colours';
import * as Styled from 'components/pageECoach/_styles/ChatBubble.style';

function SubmitButton({ inactive, onSubmit }) {
  return (
    <Styled.ButtonArea>
      <Styled.SubmitButton
        disabled={inactive}
        onClick={onSubmit}
        sx={{
          '&.Mui-disabled': {
            background: colours.neutralCool[100],
            color: '#c0c0c0',
          },
        }}
      >
        <Communication.Send01 strokeWidth={1} />
      </Styled.SubmitButton>
    </Styled.ButtonArea>
  );
}

export default SubmitButton;

SubmitButton.propTypes = {
  inactive: PropTypes.bool,
  onSubmit: PropTypes.func,
};
