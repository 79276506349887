import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// assets
import { General } from 'untitledui-js';

// components
import BookingButton from 'components/buttonBooking/_index';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';

// constants
import routesConfig from 'constants/routesConfig.json';
import userRoles from 'constants/userRoles.json';

// hooks
import { useUser } from 'contexts/UserContext';
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Button from 'components/_styles/Buttons.style';
import * as Styled from 'components/pageJourneyList/_styles/InactiveCard.style';
import * as Text from 'components/_styles/Text.style';

function InactiveCard({ journey, memberCompleted }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { userRole } = useUser();
  const lang = useUserLanguage();

  const onCardClick = () => {
    if (userRole === userRoles.MEMBER)
      history.push({
        pathname: `${routesConfig.JOURNEY_ID_PAGE}/${journey.id}`,
      });
  };

  const cta = () => {
    if (memberCompleted)
      return (
        <Button.Secondary>{t('pageJourneyList:details')}</Button.Secondary>
      );
    return <BookingButton journey={journey} />;
  };

  const numMicrolearnings = journey.microlearnings?.length;
  const journeyTitle = journey[`title_${lang}`];
  return (
    <ErrorBoundary>
      <Styled.Containter onClick={onCardClick} userrole={userRole}>
        <Styled.Cover>
          <Styled.CoverImage src={journey.cover} />
        </Styled.Cover>
        <Styled.Content>
          <Styled.InfoSection>
            <Styled.Titles>
              {numMicrolearnings > 0 && (
                <Text.CaptionSemiboldCap>
                  {t('pageJourneyList:microlearnings', {
                    count: numMicrolearnings,
                  })}
                </Text.CaptionSemiboldCap>
              )}
              <Styled.JourneyTitle>
                {memberCompleted && (
                  <Styled.StatusComplete>
                    <General.Check />
                  </Styled.StatusComplete>
                )}
                {journeyTitle && <Text.H1Bold>{journeyTitle}</Text.H1Bold>}
              </Styled.JourneyTitle>
            </Styled.Titles>
          </Styled.InfoSection>
          {userRole === userRoles.MEMBER && (
            <Styled.CtaSection>{cta()}</Styled.CtaSection>
          )}
        </Styled.Content>
      </Styled.Containter>
    </ErrorBoundary>
  );
}

InactiveCard.propTypes = {
  journey: PropTypes.object,
  memberCompleted: PropTypes.bool,
};

export default InactiveCard;
