import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import ChatBubble from 'components/pageECoach/cardTemplates/ChatBubble';
import SubmitButton from 'components/pageECoach/cardTemplates/SubmitButton';

// styles
import colours from 'constants/colours';
import * as InputStyle from 'components/pageECoach/_styles/MessageInputs.style';
import * as Page from 'components/pageECoach/_styles/ChatArea.style';

export default function SampleCases(props) {
  const { onSubmit, overview, setPendingOverview } = props;
  const { t } = useTranslation('pageEcoach');

  const samplePrompts = [
    {
      label: t(
        'sampleCases.mediate a conflict between employees who constantly disagree about shift handovers shift handovers.'
      ),
      fullPrompt: t(
        'sampleCases.Two of my cashiers have had repeated disagreements over shift handovers, leading to a tense atmosphere. How can I mediate between them to resolve the ongoing issue and prevent future conflicts?'
      ),
    },
    {
      label: t('sampleCases.boost the enthusiasm of evening shift employees.'),
      fullPrompt: t(
        'sampleCases.Recently, I noticed that our evening shift employees are not as energetic with customers as the morning staff, which has been reflected in a dip in our evening sales. What steps can I take to boost their energy levels and improve customer interactions during these hours?'
      ),
    },
    {
      label: t('sampleCases.handle a customer complaint about employee.'),
      fullPrompt: t(
        'sampleCases.A customer complained about a rude interaction with one of my staff members who has otherwise been a good employee. How can I address this incident with him constructively and use it as a learning opportunity to improve our customer service?'
      ),
    },
  ];

  const setDemoPrompt = (sample) => setPendingOverview(sample);

  return (
    <Page.ECoachSection>
      <div />
      <ChatBubble
        header={t('promptHelper.I want some advice on how to...')}
        pointer={true}
      >
        <>
          <InputStyle.ButtonsColumn>
            {samplePrompts.map((sample) => (
              <InputStyle.PromptButton
                key={sample.label}
                onClick={() => setDemoPrompt(sample.fullPrompt)}
              >
                {sample.label}
              </InputStyle.PromptButton>
            ))}
          </InputStyle.ButtonsColumn>
          {overview && (
            <>
              <InputStyle.InputTextArea
                id="outlined-multiline-static"
                multiline
                onChange={(input) => setPendingOverview(input.target.value)}
                rows={8}
                sx={{
                  fieldset: {
                    borderRadius: '10px',
                    border: `2px solid ${colours.neutralCool[200]}`,
                  },
                  maxWidth: '100%',
                  width: '800px',
                }}
                value={overview}
              />
              <SubmitButton
                inactive={overview.length === 0}
                onSubmit={onSubmit}
              />
            </>
          )}
        </>
      </ChatBubble>
      <div />
    </Page.ECoachSection>
  );
}

SampleCases.propTypes = {
  onSubmit: PropTypes.func,
  overview: PropTypes.string,
  openPromptHelper: PropTypes.func,
  setPendingOverview: PropTypes.func,
};
