import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// assets
import { Education, Time } from 'untitledui-js';

// components
import BookingButton from 'components/buttonBooking/_index';
import JoinLobbyButton from 'components/buttonJoinLobby/_index';
import FeedbackButton from 'components/buttonFeedback/_index';

// constants
import routesConfig from 'constants/routesConfig.json';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/pageJourneyId/_styles/MicrolearningCard.style';

function MicrolearningCardButtons(props) {
  const {
    cover,
    isLive,
    isOver,
    journey,
    microlearning,
    openFeedbackForm,
    wasAttended,
    wasReviewed,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const lang = useUserLanguage();

  const reloadPage = () => window.location.reload();
  const redirectToSlideshow = useCallback(() => {
    history.push({
      pathname: routesConfig.SLIDESHOW_PAGE,
      state: {
        bookingId: microlearning.booking_id,
        cover,
        journeyId: journey.id,
        journeyTitleEN: journey.title_en,
        journeyTitleFR: journey.title_fr,
        microlearningId: microlearning.id,
        microlearningTitleEN: microlearning.title_en,
        microlearningTitleFR: microlearning.title_fr,
        wasReviewed,
      },
    });
  }, [cover, history, journey, microlearning, wasReviewed]);

  const getBookingButton = () => <BookingButton journey={journey} />;

  const getJoinLobbyButton = () => (
    <JoinLobbyButton
      bookingId={microlearning.booking_id}
      journeyId={journey.id}
      microlearningId={microlearning.id}
      microlearningTitle={microlearning[`title_${lang}`]}
    />
  );

  const getFeedbackButton = () => (
    <FeedbackButton
      bookingId={microlearning.booking_id}
      open={openFeedbackForm}
      redirect={reloadPage}
    />
  );

  const getSlideshowButton = () => (
    <Buttons.Secondary onClick={redirectToSlideshow}>
      <Education.BookOpen02 />
      {t('pageJourneyId:review')}
    </Buttons.Secondary>
  );

  const getUpcomingButton = () => (
    <Buttons.PrimaryDisabled>
      <Time.HourGlass01 />
      {t('pageJourneyId:upcoming')}
    </Buttons.PrimaryDisabled>
  );

  const getFeedbackAndJoinLobbyButtons = () => (
    <Styled.TwoButtons>
      {getFeedbackButton()}
      <Styled.Button2Mobile>{getJoinLobbyButton()}</Styled.Button2Mobile>
      <Styled.Button2Desktop>
        <JoinLobbyButton
          bookingId={microlearning.booking_id}
          icon
          journeyId={journey.id}
          microlearningId={microlearning.id}
          microlearningTitle={microlearning[`title_${lang}`]}
        />
      </Styled.Button2Desktop>
    </Styled.TwoButtons>
  );

  const getFeedbackAndSlideshowButtons = () => (
    <Styled.TwoButtons>
      {getFeedbackButton()}
      <Styled.Button2Mobile>{getSlideshowButton()}</Styled.Button2Mobile>
      <Styled.Button2Desktop>
        <Buttons.SecondaryIcon onClick={redirectToSlideshow}>
          <Education.BookOpen02 />
        </Buttons.SecondaryIcon>
      </Styled.Button2Desktop>
    </Styled.TwoButtons>
  );

  const showBookingButton = microlearning.booking_starts_at === null;
  const showFeedbackButton = wasAttended && !wasReviewed;
  const showJoinLobbyButton = isLive;
  const showSlideshowButton = isOver;
  const showUpcomingButton = !isOver && !isLive;

  if (showBookingButton) return getBookingButton();
  if (showFeedbackButton && showJoinLobbyButton)
    return getFeedbackAndJoinLobbyButtons();
  if (showFeedbackButton && showSlideshowButton)
    return getFeedbackAndSlideshowButtons();
  if (showJoinLobbyButton) return getJoinLobbyButton();
  if (showFeedbackButton) return getFeedbackButton();
  if (showSlideshowButton) return getSlideshowButton();
  if (showUpcomingButton) return getUpcomingButton();
  return null;
}

MicrolearningCardButtons.propTypes = {
  cover: PropTypes.string,
  isLive: PropTypes.bool,
  isOver: PropTypes.bool,
  journey: PropTypes.object,
  microlearning: PropTypes.object,
  openFeedbackForm: PropTypes.bool,
  wasAttended: PropTypes.bool,
  wasReviewed: PropTypes.bool,
};

export default MicrolearningCardButtons;
