import React from 'react';
import PropTypes from 'prop-types';

// components
import AvatarIcons from 'components/pageOnboarding/AvatarIcons';

// styles
import * as Styled from 'components/pageJourneyId/_styles/ParticipantsCard.style';
import * as Text from 'components/_styles/Text.style';

function ParticipantsCard({ participants }) {
  if (!participants) return null;
  return (
    <Styled.Card>
      <Styled.InnerContainer>
        {participants.map((participant) => (
          <Styled.Participant key={participant.id}>
            <Styled.Avatar>
              <AvatarIcons
                avatar={participant.avatar}
                colour={participant.avatar_color}
              />
            </Styled.Avatar>
            <Text.P2Semibold>{participant.user.first_name}</Text.P2Semibold>
          </Styled.Participant>
        ))}
      </Styled.InnerContainer>
    </Styled.Card>
  );
}

export default ParticipantsCard;

ParticipantsCard.propTypes = {
  participants: PropTypes.array,
};
