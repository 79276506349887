import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';

// components
import ECoachItemListCard from 'components/pageECoach/cardTemplates/ECoachItemListCard';
import ECoachResponse from 'components/pageECoach/cardTemplates/ECoachResponse';
import Loader from 'components/pageECoach/Loader';

// constants
import colours from 'constants/colours';
import routesConfig from 'constants/routesConfig.json';

// context
import { useSessionContext } from 'contexts/ECoachSessionContext';

// hooks
import { useEcoachItemsConsumer } from 'hooks/useEcoachItemsConsumer';

// styles
import * as Page from 'components/pageECoach/_styles/ChatArea.style';
import * as Styled from 'components/pageECoach/_styles/Report.style';
import * as Text from 'components/_styles/Text.style';

// styles

function ECoachConversationListItem({ item: conversationStep }) {
  const { t } = useTranslation('pageEcoach');

  return (
    <Styled.Content>
      <Text.P2Semibold>{conversationStep.title}</Text.P2Semibold>
      <Text.P1>
        {conversationStep.short_description}
        <b style={{ color: colours.blue[500] }}>{t('See more.')}</b>
      </Text.P1>
    </Styled.Content>
  );
}

ECoachConversationListItem.propTypes = {
  item: PropTypes.object.isRequired,
};

function ECoachConversationDetails({ item }) {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture('convo_item_loaded');
  }, [posthog]);

  if (!item) {
    return <h1>No conversationStep set ?</h1>;
  }

  return (
    <>
      <Text.P1>{item?.description}</Text.P1>
      <Styled.Content>
        <Text.P1Semibold>{t('How to prepare')}</Text.P1Semibold>
        <Styled.List>
          {item?.prep?.map((pro) => (
            <li key={pro}>{pro}</li>
          ))}
        </Styled.List>
      </Styled.Content>
      <Styled.Content>
        <Text.P1Semibold>
          {t('Anticipating and handling reactions')}
        </Text.P1Semibold>
        <Styled.List>
          {item?.reactions?.map((con) => (
            <li key={con}>{con}</li>
          ))}
        </Styled.List>
      </Styled.Content>
      <Styled.Content>
        <Text.P1Semibold>{t('Example dialogue')}</Text.P1Semibold>
        <Styled.List>
          {item?.example_language?.map((con) => (
            <li key={con}>{con}</li>
          ))}
        </Styled.List>
      </Styled.Content>
    </>
  );
}

ECoachConversationDetails.propTypes = {
  item: PropTypes.object.isRequired,
};

export default function ConversationPlanCard() {
  const { session } = useSessionContext();
  const { t } = useTranslation('pageEcoach');

  const { items, selectedItem, selectItem } = useEcoachItemsConsumer(
    session.id,
    routesConfig.ECOACH.CONVERSATION_ITEMS_WS
  );

  return (
    <Page.ECoachSection>
      <ECoachResponse
        status={items.status}
        loadingTitle={t('Conversation Plan')}
        loader={<Loader />}
      >
        <ECoachItemListCard
          items={items.data}
          selectedItem={selectedItem}
          selectItem={selectItem}
          ListItemComponent={ECoachConversationListItem}
          ItemDetailsComponent={ECoachConversationDetails}
          listTitle={t('Conversation Plan')}
          isNumbered
        />
      </ECoachResponse>
    </Page.ECoachSection>
  );
}
