const SessionProgressChoices = {
  UNKNOWN: -1,
  NOT_STARTED: 0,
  CHALLENGE_VERIFICATION: 1,
  SUGGESTIONS: 2,
  CONVERSATION_PREP: 3,
  CONVERSATION_PLAN: 4,
};

const UserInputType = {
  INITIAL_PROMPT: 0,
  CHALLENGE_VERIFICATION_ANSWER: 1,
  CHALLENGE_DESCRIPTION_FEEDBACK: 2,
  CHALLENGE_VERIFICATION_CONFIRMATION_ANSWER: 3,
};

const OutputType = {
  CHALLENGE_VERIFICATION_QUESTION: 0,
  CHALLENGE_DESCRIPTION: 1,
  CHALLENGE_VERIFICATION_CONFIRMATION_QUESTION: 2,
  CHALLENGE_VERIFICATION_CONFIRMATION_DECLINE_FOLLOW_UP: 3,
  CHALLENGE_VERIFICATION_SUGGESTION_TRANSITION: 4,
};

const ChallengeVerificationState = {
  IDLE: -1,
  ASK_FOR_ANSWER: 0,
  ASK_FOR_CONFIRMATION: 1,
  ASK_FOR_FEEDBACK: 2,
};

const UserFeatureFlagType = {
  ECOACH_CHALLENGE_CLARIFICATION: 'ecoach_challenge_clarification',
  ECOACH_QDRANT_RAG: 'ecoach_qdrant_rag',
};

const BinaryChoice = {
  NO: 'No',
  YES: 'Yes',
};

// eslint-disable-next-line import/prefer-default-export
export {
  SessionProgressChoices,
  UserInputType,
  OutputType,
  ChallengeVerificationState,
  UserFeatureFlagType,
  BinaryChoice,
};
