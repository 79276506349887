import styled from 'styled-components';
import * as Cards from 'components/_styles/Cards.style';
import * as Forms from 'components/_styles/Forms.style';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Button from 'components/_styles/Buttons.style';

export const Card = styled(Cards.BasicCard)`
  gap: 20px;
  justify-content: unset;
  max-width: 540px;
  padding: 30px;
  position: relative;
  width: 100%;

  @media (max-width: ${device.mobileL}) {
    border-radius: 30px;
    gap: 15px;
    justify-content: space-between;
    padding: 15px;
  }
`;

export const FormContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 0 60px;

  @media (max-width: ${device.mobileL}) {
    gap: 15px;
    padding: 10px;
  }
`;

export const CloseSection = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled(Button.SecondaryIcon)`
  height: 40px;
  max-width: 40px;
  min-width: unset;
  padding: 0;
  width: 40px;

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const FormQuestion = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h4 {
    @media (max-width: ${device.mobileL}) {
      font-size: 20px;
    }
  }
`;

export const FormInput = styled('div')`
  display: flex;
  gap: 30px;
  justify-content: space-between;

  @media (max-width: ${device.mobileL}) {
    gap: 15px;
  }
`;

export const Stars = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const Star = styled('div')`
  svg {
    cursor: pointer;
    height: 50px;
    path {
      stroke: ${colours.warning[500]};
    }
    width: 50px;

    @media (max-width: ${device.mobileL}) {
      height: 35px;
      width: 35px;
    }
  }

  &:hover {
    svg {
      fill: ${colours.warning[200]};
    }
  }

  &.filled {
    svg {
      fill: ${colours.warning[500]};
    }

    &:hover {
      svg {
        fill: ${colours.warning[500]};
      }
    }
  }
`;

export const TextArea = styled(Forms.TextareaField)`
  background-color: ${colours.neutralCool[50]};
  border: none;
  border-radius: 32px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  height: 200px;
  padding: 20px 24px;
  resize: none;
  width: 360px;

  @media (max-width: ${device.tablet}) {
    width: 100%;
  }
`;
