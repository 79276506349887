import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Time } from 'untitledui-js';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyId/_styles/MicrolearningCard.style';
import * as Text from 'components/_styles/Text.style';

function MicrolearningCardDate({ isLive, isOver, microlearning, wasAttended }) {
  const { t } = useTranslation();
  const lang = useUserLanguage();

  const getText = () => {
    if (isOver && wasAttended) {
      const completedOn =
        microlearning.microlearning_completed_at ||
        microlearning.offlive_completed_at;

      return t('pageJourneyId:completed_on', {
        date: dayjs(completedOn).locale(lang).format('LLL'),
      });
    }
    return dayjs(microlearning.booking_starts_at).locale(lang).format('LLL');
  };

  if (isLive)
    return (
      <Styled.LiveChip>
        <Time.Clock />
        <Text.P2Semibold>{`${t(
          'pageJourneyId:live'
        )} | ${getText()}`}</Text.P2Semibold>
      </Styled.LiveChip>
    );

  return (
    <Styled.DateSection>
      <Time.Clock />
      <Text.P2Semibold>{`${getText()} (${dayjs.tz.guess()})`}</Text.P2Semibold>
    </Styled.DateSection>
  );
}

MicrolearningCardDate.propTypes = {
  isLive: PropTypes.bool,
  isOver: PropTypes.bool,
  microlearning: PropTypes.object,
  wasAttended: PropTypes.bool,
};

export default MicrolearningCardDate;
