import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Time, Users } from 'untitledui-js';

// components
import ActiveCard from 'components/pageJourneyList/ActiveCard';
import CTAs from 'components/pageJourneyList/memberView/CTAs';
import ProgressBar from 'components/pageJourneyList/ProgressBar';

// helpers & hooks
import isFinished from 'helpers/isFinished';
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyList/_styles/ActiveCardMember.style';
import * as Text from 'components/_styles/Text.style';

function ActiveCardMember({ journey }) {
  const { t } = useTranslation();
  const lang = useUserLanguage();

  const checkBookingInFuture = (microlearning) =>
    !isFinished(microlearning.booking_starts_at);

  const checkBookingInPast = (microlearning) =>
    isFinished(microlearning.booking_starts_at);

  const checkMissingFeedback = (microlearning) =>
    microlearning.reviewed === false;

  const upcomingMicrolearning =
    journey.microlearnings.find(checkBookingInFuture);

  const microlearningMissingFeedback =
    journey.microlearnings.find(checkMissingFeedback);

  const getCurrentMicrolearning = (microlearnings) => {
    if (upcomingMicrolearning) return upcomingMicrolearning;
    if (microlearningMissingFeedback) return microlearningMissingFeedback;
    return microlearnings[microlearnings.length - 1];
  };

  const currentMicrolearning = getCurrentMicrolearning(journey.microlearnings);
  const totalMicrolearnings = journey.microlearnings.length;
  const pastMicrolearnings = journey.microlearnings?.filter((microlearning) =>
    checkBookingInPast(microlearning)
  ).length;

  const showEvalCard =
    journey.microlearnings_completed_at !== null &&
    !microlearningMissingFeedback &&
    journey.evaluation_completed_at === null;

  const featuredInfo = () => {
    if (!showEvalCard) {
      const owlPic = journey.selected_timetable?.owl?.picture;
      const owlName = journey.selected_timetable?.owl?.user?.first_name;
      const numParticipants = journey.selected_timetable?.participants_count;
      return (
        <>
          <Styled.SessionSection>
            <Styled.OwlImageContainer>
              {owlPic && <Styled.OwlImage src={owlPic} />}
            </Styled.OwlImageContainer>
            {owlName && <Text.P1>{owlName}</Text.P1>}
          </Styled.SessionSection>
          <Styled.SessionSection>
            <Users.User01 />
            {numParticipants && (
              <Text.P1>
                {t('pageJourneyList:participants', {
                  count: numParticipants,
                })}
              </Text.P1>
            )}
          </Styled.SessionSection>
          <Styled.SessionSection>
            <Time.Clock />
            <Text.P1>{`${dayjs(currentMicrolearning?.booking_starts_at)
              .locale(lang)
              .format('LLL')} (${dayjs.tz.guess()})`}</Text.P1>
          </Styled.SessionSection>
        </>
      );
    }
    return <></>;
  };

  const progressBar = () => (
    <ProgressBar progress={pastMicrolearnings} total={totalMicrolearnings} />
  );

  const cta = () => (
    <CTAs
      journey={journey}
      microlearning={currentMicrolearning}
      showEvalCard={showEvalCard}
    />
  );

  return (
    <ActiveCard
      cta={cta}
      currentMicrolearning={currentMicrolearning}
      evaluationView={showEvalCard}
      featuredInfo={featuredInfo}
      journey={journey}
      progressBar={progressBar}
    />
  );
}

ActiveCardMember.propTypes = {
  journey: PropTypes.object,
};

export default ActiveCardMember;
