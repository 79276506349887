import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// components
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import RemoveIcon from '@mui/icons-material/Remove';
import ChatBubble from 'components/pageECoach/cardTemplates/ChatBubble';
import FillInTheBlanksCard from 'components/pageECoach/overviewForm/FillInTheBlanksCard';

// data
import helperPrompts from 'components/pageECoach/overviewForm/prompts';

// styles
import * as DividerStyle from 'components/pageECoach/_styles/Divider.style';
import * as InputStyle from 'components/pageECoach/_styles/MessageInputs.style';
import * as PromptHelperStyle from 'components/pageECoach/_styles/PromptHelper.style';
import * as Text from 'components/_styles/Text.style';

export default function PromptHelper({
  onSubmit,
  setPendingOverview,
  onBackClicked,
}) {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [fillInBlanksFormOpen, setFillInBlanksFormOpen] = useState(false);
  const [open, setOpen] = useState({
    'Overcome a challenge in my team': false,
    "Elevate my team's success": false,
    'Improve team morale': false,
    'Grow as a leader': false,
    'Improve workplace interactions': false,
  });

  const showFillInBlanksForm = useCallback(() => {
    setFillInBlanksFormOpen(true);
    setOpen({
      'Overcome a challenge in my team': false,
      "Elevate my team's success": false,
      'Improve team morale': false,
      'Grow as a leader': false,
      'Improve workplace interactions': false,
    });
    posthog?.capture('user_clicks_prompt_helper');
  }, [posthog]);

  useEffect(() => {
    if (selectedPrompt) showFillInBlanksForm(true);
  }, [selectedPrompt, showFillInBlanksForm]);

  const selectPrompt = (option) => setSelectedPrompt(option);

  const toggleCategory = (category) =>
    setOpen({ ...open, [category]: !open[category] });

  const getContent = () => {
    const inputSelect = (option) => (
      <InputStyle.PromptButton
        key={option.label}
        onClick={() => selectPrompt(option.prompt)}
      >
        <Text.P1Semibold>{t(`promptHelper.${option.label}`)}</Text.P1Semibold>
      </InputStyle.PromptButton>
    );

    const inputGroup = (helper) => (
      <PromptHelperStyle.AdviceList
        aria-labelledby="nested-list-subheader"
        key={helper.category}
        subheader={
          <PromptHelperStyle.OptionsToggle
            component="div"
            id="nested-list-subheader"
            onClick={() => toggleCategory(helper.category)}
          >
            <Text.CaptionCap>
              {t(`promptHelper.${helper.category}`)}
            </Text.CaptionCap>
            {open[helper.category] ? <RemoveIcon /> : <AddIcon />}
          </PromptHelperStyle.OptionsToggle>
        }
      >
        <Collapse in={open[helper.category]} timeout="auto" unmountOnExit>
          <PromptHelperStyle.PromptOptions component="div">
            {helper.options.map((option) => inputSelect(option))}
          </PromptHelperStyle.PromptOptions>
        </Collapse>
      </PromptHelperStyle.AdviceList>
    );

    return (
      <>
        {helperPrompts.map((helper) => inputGroup(helper))}
        <DividerStyle.Divider />
        {!fillInBlanksFormOpen && (
          <InputStyle.PromptButton onClick={showFillInBlanksForm}>
            {t('promptHelper.other')}
          </InputStyle.PromptButton>
        )}
        {fillInBlanksFormOpen && (
          <FillInTheBlanksCard
            onSubmit={onSubmit}
            selectedPrompt={
              selectedPrompt ? t(`promptHelper.${selectedPrompt}`) : ''
            }
            setPendingOverview={setPendingOverview}
          />
        )}
      </>
    );
  };

  return (
    <ChatBubble
      navigation={{
        action: onBackClicked,
        text: t('promptHelper.back'),
      }}
      disableScroll={true}
      header={t('promptHelper.I want some advice on how to...')}
    >
      {getContent()}
    </ChatBubble>
  );
}

PromptHelper.propTypes = {
  onSubmit: PropTypes.func,
  setPendingOverview: PropTypes.func,
  onBackClicked: PropTypes.func,
};
