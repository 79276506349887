import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import Dialog from '@mui/material/Dialog';
import BookingForm from 'components/buttonBooking/BookingForm';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/buttonBooking/_styles/BookingForm.style';

function BookingButton({ journey }) {
  const { t } = useTranslation();
  const { data, callApi: getTimetable } = useApi();
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (e) => {
    e.stopPropagation();
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    getTimetable(`/journeys/${journey.id}/timetables`);
  }, [getTimetable, journey.id]);

  if (data.status !== 'success') return null;
  if (data.response.length === 0)
    return (
      <Buttons.Secondary>{t('buttonBooking:comingSoon')}</Buttons.Secondary>
    );
  return (
    <>
      <Buttons.Primary onClick={openModal}>
        {t('buttonBooking:buttonBookingLabel')}
      </Buttons.Primary>
      <Dialog
        open={modalOpen}
        onClose={closeModal}
        onClick={(e) => e.stopPropagation()}
        PaperComponent={Styled.Card}
      >
        <ErrorBoundary>
          <BookingForm
            handleModalClose={closeModal}
            journey={journey}
            timetables={data.response}
          />
        </ErrorBoundary>
      </Dialog>
    </>
  );
}

BookingButton.propTypes = {
  journey: PropTypes.object,
};

export default BookingButton;
