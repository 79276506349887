import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import SlideFirst from 'components/pageSlideshow/SlideFirst';
import SlideGeneral from 'components/pageSlideshow/SlideGeneral';
import SlideLast from 'components/pageSlideshow/SlideLast';

// hooks
import useApi from 'hooks/useApi';
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageSlideshow/_styles/_index.style';
import * as Text from 'components/_styles/Text.style';

export default function SlideshowPage() {
  const { state } = useLocation();
  const { callApi: completeOfflive } = useApi();
  const { data: slideshow, callApi: getSlideshow } = useApi();
  const lang = useUserLanguage();

  const pageInfo = state;
  const {
    bookingId,
    cover,
    journeyId,
    journeyTitleEN,
    journeyTitleFR,
    microlearningTitleEN,
    microlearningTitleFR,
    microlearningId,
    wasReviewed,
  } = pageInfo;

  const [showEndSlide, setShowEndSlide] = useState(false);
  const [showIntroSlide, setShowIntroSlide] = useState(true);

  const markComplete = () => {
    setShowEndSlide(true);
    completeOfflive(`/bookings/${bookingId}/complete-offlive/`, 'post');
  };

  const microlearningTitle =
    lang === 'en' ? microlearningTitleEN : microlearningTitleFR;
  const journeyTitle = lang === 'en' ? journeyTitleEN : journeyTitleFR;

  useEffect(() => {
    getSlideshow(`/microlearnings/${microlearningId}/slideshows/`);
  }, [getSlideshow, microlearningId]);

  if (slideshow.status === 'loading') return <ApiLoading />;
  return (
    <ErrorBoundary>
      <Styled.Page>
        <Styled.Header>
          <Text.H4Bold>{microlearningTitle}</Text.H4Bold>
        </Styled.Header>
        <Styled.Slideshow>
          {showIntroSlide && (
            <SlideFirst
              cover={cover}
              journeyTitle={journeyTitle}
              microlearningTitle={microlearningTitle}
              setShowIntroSlide={setShowIntroSlide}
            />
          )}
          {!showIntroSlide && !showEndSlide && (
            <SlideGeneral
              markComplete={markComplete}
              setShowIntroSlide={setShowIntroSlide}
              slides={slideshow.response.slides}
            />
          )}
          {showEndSlide && (
            <SlideLast
              journeyId={journeyId}
              microlearningId={microlearningId}
              wasReviewed={wasReviewed}
            />
          )}
        </Styled.Slideshow>
      </Styled.Page>
    </ErrorBoundary>
  );
}
