import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import { General } from 'untitledui-js';

// components
import EndCard from 'components/buttonBooking/EndCard';
import LanguageSelection from 'components/buttonBooking/LanguageSelection';
import ScheduleOption from 'components/buttonBooking/ScheduleOption';

// hooks
import useApi from 'hooks/useApi';
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Button from 'components/_styles/Buttons.style';
import * as Text from 'components/_styles/Text.style';
import * as Styled from 'components/buttonBooking/_styles/BookingForm.style';

function BookingForm({ handleModalClose, journey, timetables }) {
  const { t } = useTranslation();
  const { callApi } = useApi();
  const lang = useUserLanguage();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [languageSelection, setLanguageSelection] = useState(lang);
  const [recurrence, setRecurrence] = useState();
  const [selectedTimetable, setSelectedTimetable] = useState();

  const handleTimetableSelect = (timetable) => setSelectedTimetable(timetable);
  const onSubmit = () => {
    callApi(`/timetables/${selectedTimetable.id}/`, 'post');
    setFormSubmitted(true);
  };

  const journeyTitle = journey[`title_${lang}`];
  const timetablesInLanguage = timetables.filter(
    (timetable) => timetable.language === languageSelection
  );

  if (formSubmitted)
    return <EndCard journeyTitle={journeyTitle} recurrence={recurrence} />;
  return (
    <>
      <Styled.CloseSection>
        <Styled.CloseButton onClick={handleModalClose}>
          <General.XClose />
        </Styled.CloseButton>
      </Styled.CloseSection>
      <Styled.ContentSection>
        <Styled.Header>
          <Text.CaptionSemiboldCap>
            {t('buttonBooking:formLabel')}
          </Text.CaptionSemiboldCap>
          {journeyTitle && <Text.H2Bold>{journeyTitle}</Text.H2Bold>}
        </Styled.Header>
        <LanguageSelection
          languageSelection={languageSelection}
          setLanguageSelection={setLanguageSelection}
        />
        <Styled.ScheduleSection>
          <Styled.FormInputText>
            {t('buttonBooking:selectSchedule')}
          </Styled.FormInputText>
          {timetablesInLanguage.length === 0 && (
            <Styled.ScheduleEmpty>
              <Styled.FormInputText>
                {t('buttonBooking:noTimetables')}
              </Styled.FormInputText>
            </Styled.ScheduleEmpty>
          )}
          {timetablesInLanguage.map((timetable) => (
            <ScheduleOption
              handleTimetableSelect={handleTimetableSelect}
              key={timetable.id}
              selected={timetable.id === selectedTimetable?.id}
              setRecurrence={setRecurrence}
              timetable={timetable}
            />
          ))}
        </Styled.ScheduleSection>
      </Styled.ContentSection>
      <Styled.SubmitSection>
        {selectedTimetable ? (
          <Button.Primary onClick={onSubmit}>
            {t('buttonBooking:buttonBookingLabel')}
          </Button.Primary>
        ) : (
          <Button.PrimaryDisabled>
            {t('buttonBooking:buttonBookingLabel')}
          </Button.PrimaryDisabled>
        )}
      </Styled.SubmitSection>
    </>
  );
}

export default BookingForm;

BookingForm.propTypes = {
  handleModalClose: PropTypes.func,
  journey: PropTypes.object,
  timetables: PropTypes.array,
};
