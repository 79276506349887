import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// assets
import { General } from 'untitledui-js';

// style
import * as Header from 'components/pageECoach/_styles/Header.style';
import * as Styled from 'components/pageECoach/_styles/DocumentRow.style';

export default function DocumentDownloadButton({ href, name, onPage }) {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();
  const signalPosthog = () =>
    posthog?.capture('user_downloads_eCoach_document');

  const ButtonComponent = onPage ? Header.HeaderButton : Styled.Button;

  return (
    <a
      download={name}
      href={href}
      style={{ textDecoration: 'none' }}
      onClick={signalPosthog}
    >
      <ButtonComponent>
        <General.Download01 size={'15'} />
        {t('Download')}
      </ButtonComponent>
    </a>
  );
}

DocumentDownloadButton.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string,
  onPage: PropTypes.bool,
};
