import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import { Stack } from '@mui/material';

// context
import { useUser } from 'contexts/UserContext';

// helpers
import bookingLink from 'helpers/bookingLink';

// styles
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import * as History from 'components/pageECoach/_styles/History.style';
import * as Text from 'components/_styles/Text.style';

export default function UserInterviewBooking() {
  const { t } = useTranslation('pageEcoach');
  const { data: user } = useUser();

  return (
    <Stack spacing={4}>
      <Text.SubheadingSemibold>{t('Notifications')}</Text.SubheadingSemibold>
      <Cards.MultiCardSection>
        <History.Card style={{ cursor: 'unset', height: 'unset' }}>
          <Text.P2Semibold>
            {t('userInterview.A limited time offer for', {
              company: user.organization.name,
            })}
          </Text.P2Semibold>
          <Text.P1>
            {t(
              'userInterview.Ready to make your job easier and more efficient? Book a free 15-minute chat with our experts today! Let us understand your needs and show you how we can simplify your work.',
              { name: user.nickname || user.first_name }
            )}
          </Text.P1>
          <Cards.Button onClick={() => bookingLink(user.language)}>
            {t('userInterview.Book your free chat now')}
          </Cards.Button>
        </History.Card>
      </Cards.MultiCardSection>
    </Stack>
  );
}

UserInterviewBooking.propTypes = {};
