import React, { useEffect, useState } from 'react';

// components
import DescriptionCard from 'components/pageECoach/overviewForm/DescriptionCard';
import PromptHelper from 'components/pageECoach/overviewForm/PromptHelper';
import SampleCases from 'components/pageECoach/overviewForm/SampleCases';
import { usePostHog, useFeatureFlagEnabled } from 'posthog-js/react';

// style
import {
  SessionProgressChoices,
  UserFeatureFlagType,
} from 'components/pageECoach/utils';
import { useSessionContext } from 'contexts/ECoachSessionContext';
import PropTypes from 'prop-types';

export default function OverviewForm({ handleSubmit }) {
  const { session, updateSession } = useSessionContext();
  const posthog = usePostHog();

  const [displayPromptHelper, setDisplayPromptHelper] = useState(false);
  const [displaySampleCases, setDisplaySampleCases] = useState(false);
  const [pendingOverview, setPendingOverview] = useState('');

  const isChallengeClarificationEnabled = useFeatureFlagEnabled(
    UserFeatureFlagType.ECOACH_CHALLENGE_CLARIFICATION
  );

  const openPromptHelper = () => {
    setDisplayPromptHelper(true);
    setDisplaySampleCases(false);
  };

  const openSampleCases = () => {
    setDisplayPromptHelper(false);
    setDisplaySampleCases(true);
  };

  useEffect(() => {
    setPendingOverview(session?.context?.description || '');
  }, [session]);

  const onSubmit = () => {
    const nextProgress = isChallengeClarificationEnabled
      ? SessionProgressChoices.CHALLENGE_VERIFICATION
      : SessionProgressChoices.SUGGESTIONS;

    updateSession(session?.id, {
      context: { description: pendingOverview },
      progress: nextProgress,
    });
    posthog?.capture('user_submits_eCoach_challenge');
    handleSubmit();
  };

  if (displayPromptHelper)
    return (
      <PromptHelper
        onSubmit={onSubmit}
        setPendingOverview={setPendingOverview}
        onBackClicked={() => setDisplayPromptHelper(false)}
      />
    );

  if (displaySampleCases)
    return (
      <SampleCases
        onSubmit={onSubmit}
        overview={pendingOverview}
        setPendingOverview={setPendingOverview}
      />
    );

  return (
    <DescriptionCard
      onSubmit={onSubmit}
      overview={pendingOverview}
      openPromptHelper={openPromptHelper}
      openSampleCases={openSampleCases}
      setPendingOverview={setPendingOverview}
    />
  );
}

OverviewForm.propTypes = {
  handleSubmit: PropTypes.func,
};
