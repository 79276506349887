import styled from 'styled-components';
import * as Button from 'components/_styles/Buttons.style';
import * as Cards from 'components/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';
import colours from 'constants/colours';
import device from 'constants/devices';
import { ScheduleOption } from 'components/buttonBooking/_styles/ScheduleOption.style';

export const Card = styled(Cards.BasicCard)`
  gap: 30px;
  height: 775px;
  justify-content: flex-start;
  padding: 40px;
  position: relative;
  width: 580px;

  @media (max-width: ${device.tablet}) {
    border-radius: 35px;
    gap: 20px;
    height: 90%;
    min-height: 550px;
    padding: 30px;
    width: 90%;
  }
`;

export const CloseSection = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled(Button.SecondaryIcon)`
  height: 40px;
  max-width: 40px;
  min-width: unset;
  padding: 0;
  width: 40px;

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const ContentSection = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  padding: 0 40px;

  @media (max-width: ${device.tablet}) {
    padding: 0;
  }
`;

export const Header = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FormInputSelect = styled('div')`
  background-color: ${colours.neutralCool[50]};
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  padding: 18px 22px;
`;

export const FormInputText = styled(Text.P2Semibold)`
  color: ${colours.neutralCool[500]};
`;

export const ScheduleSection = styled(FormInputSelect)`
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  height: 96px;
  padding: 30px;
  overflow: auto;

  @media (max-width: ${device.tablet}) {
    gap: 15px;
    padding: 22px;
  }
`;

export const ScheduleEmpty = styled(ScheduleOption)`
  cursor: default;
`;

export const SubmitSection = styled('div')`
  display: flex;
  padding: 0 40px 40px 40px;

  @media (max-width: ${device.tablet}) {
    padding: 0;
  }
`;
