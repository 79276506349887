import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Shapes } from 'untitledui-js';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import FeedbackForm from 'components/buttonFeedback/FeedbackForm';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/buttonFeedback/_styles/FeedbackCard.style';

function FeedbackButton({ bookingId, open, redirect }) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(open);
  const handleModalClose = () => setModalOpen(false);
  const handleFeedbackClick = () => setModalOpen(true);

  return (
    <>
      <Buttons.Secondary onClick={handleFeedbackClick}>
        <Shapes.Star01 />
        {t('buttonFeedback:rate')}
      </Buttons.Secondary>
      <Dialog
        open={modalOpen || false}
        onClose={handleModalClose}
        onClick={(e) => e.stopPropagation()}
        PaperComponent={Styled.Card}
      >
        <ErrorBoundary>
          <FeedbackForm
            bookingId={bookingId}
            handleModalClose={handleModalClose}
            redirect={redirect}
          />
        </ErrorBoundary>
      </Dialog>
    </>
  );
}

FeedbackButton.propTypes = {
  bookingId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  redirect: PropTypes.func,
};

export default FeedbackButton;
