import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Arrow } from 'untitledui-js';

// styles
import * as ChatBubbleStyled from 'components/pageECoach/_styles/ChatBubble.style';
import * as Text from 'components/_styles/Text.style';
import useAutoScroll from 'hooks/useAutoScroll';

function ChatBubble({
  children,
  disableScroll,
  header,
  navigation,
  pointer,
  sentByUser,
  subheader,
}) {
  const { t } = useTranslation('pageEcoach');
  const ref = useAutoScroll();

  const getHeader = () => (
    <ChatBubbleStyled.Headers>
      {navigation && (
        <ChatBubbleStyled.Navigation onClick={navigation.action}>
          <Arrow.ChevronLeft />
          <Text.P1>{navigation.text}</Text.P1>
        </ChatBubbleStyled.Navigation>
      )}
      <Text.SubheadingSemibold>{header}</Text.SubheadingSemibold>
      {subheader && (
        <ChatBubbleStyled.Subheader>{subheader}</ChatBubbleStyled.Subheader>
      )}
    </ChatBubbleStyled.Headers>
  );

  if (pointer && !sentByUser)
    return (
      <ChatBubbleStyled.Section eCoach={true}>
        <ChatBubbleStyled.ECoachBubbleWithPointer
          ref={disableScroll ? null : ref}
        >
          {header && getHeader()}
          {children}
        </ChatBubbleStyled.ECoachBubbleWithPointer>
        <ChatBubbleStyled.Label>eCoach</ChatBubbleStyled.Label>
      </ChatBubbleStyled.Section>
    );

  if (!pointer && !sentByUser)
    return (
      <ChatBubbleStyled.Section eCoach={true}>
        <ChatBubbleStyled.ChatBubble ref={disableScroll ? null : ref}>
          {header && getHeader()}
          {children}
        </ChatBubbleStyled.ChatBubble>
      </ChatBubbleStyled.Section>
    );

  return (
    <ChatBubbleStyled.Section>
      <ChatBubbleStyled.UserBubbleWithPointer ref={disableScroll ? null : ref}>
        {header && getHeader()}
        {children}
      </ChatBubbleStyled.UserBubbleWithPointer>
      <ChatBubbleStyled.Label>{t('You')}</ChatBubbleStyled.Label>
    </ChatBubbleStyled.Section>
  );
}

export default ChatBubble;

ChatBubble.propTypes = {
  header: PropTypes.string,
  disableScroll: PropTypes.bool,
  navigation: PropTypes.shape({
    action: PropTypes.func,
    text: PropTypes.string,
  }),
  pointer: PropTypes.bool,
  subheader: PropTypes.string,
  children: PropTypes.node, // Specifies that children could be anything that React can render
  sentByUser: PropTypes.bool,
};
