import styled from 'styled-components';
import device from 'constants/devices';

export const Card = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 60px;
  justify-content: unset;
  padding: 90px;

  @media (max-width: ${device.mobileL}) {
    gap: 30px;
    padding: 30px;
  }
`;

export const TopSection = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;

  @media (max-width: ${device.mobileL}) {
    gap: 15px;
  }
`;

export const SurveyDoneImage = styled('img')`
  box-sizing: border-box;
  max-width: 120px;
  padding: 0;
`;
