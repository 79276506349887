import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import FinalCardImg from 'assets/finalCard.svg';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/buttonFeedback/_styles/EndCard.style';
import * as Text from 'components/_styles/Text.style';

function EndCard({ redirect }) {
  const { t } = useTranslation();
  return (
    <Styled.Card>
      <Styled.TopSection>
        <Styled.SurveyDoneImage src={FinalCardImg} />
        <Text.H2Bold>{t('buttonFeedback:thanks')}</Text.H2Bold>
      </Styled.TopSection>
      <Buttons.Primary onClick={redirect}>
        {t('buttonFeedback:done')}
      </Buttons.Primary>
    </Styled.Card>
  );
}

EndCard.propTypes = {
  redirect: PropTypes.func,
};

export default EndCard;
