import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// styles
import * as Styled from 'components/pageJourneyId/_styles/Tabs.style';

export default function Tabs({ activeTab, toggleTab }) {
  const { t } = useTranslation();

  const microlearningsActive = activeTab === 'microlearnings';
  const detailsActive = activeTab === 'details';
  const participantsActive = activeTab === 'participants';

  return (
    <Styled.Tabs>
      <Styled.Tab
        active={microlearningsActive}
        onClick={() => toggleTab('microlearnings')}
      >
        <Styled.TabText active={microlearningsActive}>
          {t('pageJourneyId:microlearnings')}
        </Styled.TabText>
      </Styled.Tab>
      <Styled.Tab active={detailsActive} onClick={() => toggleTab('details')}>
        <Styled.TabText active={detailsActive}>
          {t('pageJourneyId:details')}
        </Styled.TabText>
      </Styled.Tab>
      <Styled.Tab
        active={participantsActive}
        onClick={() => toggleTab('participants')}
      >
        <Styled.TabText active={participantsActive}>
          {t('pageJourneyId:people')}
        </Styled.TabText>
      </Styled.Tab>
    </Styled.Tabs>
  );
}

Tabs.propTypes = {
  activeTab: PropTypes.string,
  toggleTab: PropTypes.func,
};
