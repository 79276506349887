import styled, { css } from 'styled-components';
import * as Text from 'components/_styles/Text.style';
import colours from 'constants/colours';
import device from 'constants/devices';

export const ScheduleOption = styled('div')`
  background-color: ${colours.shade[0]};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;

  @media (max-width: ${device.tablet}) {
    padding: 15px;
  }

  ${(props) =>
    props.selected &&
    css`
      border: 3px solid ${colours.primary[500]};
      padding: 17px;

      @media (max-width: ${device.tablet}) {
        padding: 12px;
      }
    `}
`;

export const DateSection = styled('div')`
  display: flex;
  gap: 10px;
`;

export const DateConnectorIcon = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DateConnectorDot = styled('div')`
  background-color: ${colours.neutralCool[800]};
  border-radius: 30px;
  height: 10px;
  width: 10px;
`;

export const DateConnectorLine = styled('div')`
  background-color: ${colours.neutralCool[800]};
  flex-grow: 1;
  width: 2px;
`;

export const Dates = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DateDetailsSection = styled('div')`
  align-items: center;
  display: flex;
  gap: 10px;

  svg path {
    stroke: ${colours.neutralCool[500]};
  }
`;

export const DateDetailsText = styled(Text.P1)`
  color: ${colours.neutralCool[500]};
`;
