import React, { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';

// assets
import LogoText from 'assets/logoText.svg';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';

// helpers
import supportLink from 'helpers/supportLink';
import translations from 'components/pageSignIn/_translations';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/pageSignIn/SignIn.style';
import * as StyledLanguge from 'components/navbar/_styles/LanguageToggle.style';
import * as Text from 'components/_styles/Text.style';

export default function SignIn() {
  const { callApi } = useApi();
  const posthog = usePostHog();
  const [email, setEmail] = useState('');
  const [formOpen, setFormOpen] = useState(true);
  const [language, setLanguage] = useState('en');

  const handleEmailChange = (event) => setEmail(event.target.value);
  const toggleLanguage = (newLanguage) => setLanguage(newLanguage);
  const handleFormOpen = () => setFormOpen(true);

  const handleLogin = () => {
    callApi('/login/', 'post', { email });
    posthog?.capture('user_requested_login_link');
    setFormOpen(false);
  };

  useEffect(() => {
    localStorage.removeItem('authorization');
  }, []);

  return (
    <Styled.ScreenContainer>
      <Styled.LogoText src={LogoText} />
      <Styled.FormSection>
        <ErrorBoundary>
          <Styled.Card>
            <Text.H2>{translations[language].login}</Text.H2>
            <Text.P2Semibold>
              {translations[language].instructions1}
              <br />
              {translations[language].instructions2}
            </Text.P2Semibold>
            {formOpen ? (
              <>
                <Styled.Input
                  autoComplete="email"
                  autoFocus
                  fullWidth
                  id="email"
                  InputLabelProps={{
                    style: { color: 'grey', fontSize: 16 },
                  }}
                  label={translations[language].emailLabel}
                  margin="normal"
                  name="email"
                  onChange={handleEmailChange}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      if (email.length !== 0) handleLogin();
                      ev.preventDefault();
                    }
                  }}
                  value={email}
                />
                <Buttons.Primary onClick={handleLogin}>
                  {translations[language].submitButton}
                </Buttons.Primary>
              </>
            ) : (
              <Styled.Success>
                <Styled.SuccessMessage>
                  {translations[language].successMessage}
                </Styled.SuccessMessage>
                <Styled.Resend onClick={handleFormOpen}>
                  {translations[language].resend}
                </Styled.Resend>
              </Styled.Success>
            )}
          </Styled.Card>
          <Styled.LanguageToggle>
            <StyledLanguge.Languages>
              {language === 'en' ? (
                <>
                  <StyledLanguge.ToggleButton>
                    <Text.P1>EN</Text.P1>
                  </StyledLanguge.ToggleButton>
                  <StyledLanguge.Switch onClick={() => toggleLanguage('fr')}>
                    <Text.P1>FR</Text.P1>
                  </StyledLanguge.Switch>
                </>
              ) : (
                <>
                  <StyledLanguge.Switch onClick={() => toggleLanguage('en')}>
                    <Text.P1>EN</Text.P1>
                  </StyledLanguge.Switch>
                  <StyledLanguge.ToggleButton>
                    <Text.P1>FR</Text.P1>
                  </StyledLanguge.ToggleButton>
                </>
              )}
            </StyledLanguge.Languages>
          </Styled.LanguageToggle>
          <Styled.HelpLink target="_blank" href={supportLink(language)}>
            {translations[language].help}
          </Styled.HelpLink>
        </ErrorBoundary>
      </Styled.FormSection>
    </Styled.ScreenContainer>
  );
}
